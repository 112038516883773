import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Row} from '../../utils/elements';
import {Head} from '../../utils/interfaces';
import {LanguageService} from '../../utils/language.service';

/**
 * @since PM (20.07.2020)
 * this component is a simple wrapper of the title.component;
 * its purpose is to simplify the theme development
 */
@Component({
  selector: 'row-headline',
  templateUrl: './headline.component.html',
  styleUrls: ['./headline.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class HeadlineComponent extends Row {
  private _head: Head
  private _date = ''
  readonly slug = 'headline'


  @Input() target?: string

  @Input()
  set head(head: Head){
    this._head = head
    if(head.date && Object.keys(head.date).length) this._date = (head.config[LanguageService.lang] || head.config._).replace(
      /[a-z_]+/g,
      key => {
        const value = head.date[key] as string
        if(value){
          //DO NOT MERGE
          if(key.includes('_text_')) return this.theo(`date_${value.toLowerCase()}`)
          else if(key === 'day'){
            switch (this.language.theo) {
              case LanguageService.thesauri.de: return (value.length === 1 ? '0'+ value : value) +'.'
              case LanguageService.thesauri.en:
                if(value === '1') return '1st'
                else if(value === '2') return '2nd'
                else if(value === '3') return '3rd'
                else return value +'th'

              default: return value.length === 1 ? '0'+ value : value
            }
          }else if(value.length === 1) return '0'+ value
          else return value
        }else return this.lipsum(key)
      }
    )
  }
  get head(): Head { return this._head }

  @Input() scroller?: any
  @Input() uppercase = false
  @Input() icon = true


  get date(): string { return this._date }
}
