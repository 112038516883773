import {Component, Input, ViewEncapsulation} from '@angular/core';
import * as L from 'leaflet';
import {Column} from '../../utils/elements';

/**
 * this script is used to create the chart line logic
 * @since PM (xx.xx.2020)
 * @see https://www.digitalocean.com/community/tutorials/angular-angular-and-leaflet
 * @see https://ngrefs.com/latest/templates/touch-events
 *      https://medium.com/angular-in-depth/gestures-in-an-angular-application-dde71804c0d0
 */
@Component({
  selector: 'column-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class MapComponent extends Column {
  @Input() //map coordinates
  set coordinates(coordinates: number[][]){
    this._coordinates = coordinates
    this.render()
  }
  get coordinates(): number[][] { return this._coordinates || [] }

  private _coordinates: number[][]
  private _map: any //the object representing the rendered map
  readonly slug = 'map'
  readonly flag = 'fullscreen' //the fullscreen flag
  public fullscreen = false
  public mask = false //mask controller


  /**
   * singleton setter
   * @param map
   */
  set map(map: any){
    if(this._map === undefined) this._map = map
  }
  get map(){ return this._map }


  /**
   * this function is used to set the map
   * https://leafletjs.com/examples/quick-start/
   */
  ngAfterViewInit(){
    super.ngAfterViewInit()

    window.addEventListener('mask_map', () => this.mask = true)
    this.render()
  }

  /**
   * this function is used to toggle the map between its default mode & its fullscreen mode
   */
  toggle(){
    this.fullscreen = !this.fullscreen //add/remove the flag
    document.documentElement.classList.toggle(this.flag) //used to remove the document scrollbar in the fullscreen mode
    this.resize()
  }

  /**
   * this function is used to resize the map asynchronously
   */
  resize(){
    setTimeout( //resize the map
      () => this.map.invalidateSize(true),
      200
    )
  }

  /**
   * this function is used to generate/render the map & add its snippet to the DOM
   * @see https://leafletjs.com/reference-1.6.0.html
   */
  private render(){
    const
      elt = this.eltId(this.slug),
      coordinates = this.coordinates,
      len = coordinates.length

    if(elt && elt.classList.contains('empty') && len > 0){ //TRUE if the map hasn't been set jet
      elt.classList.remove('empty') //set the lock
      setTimeout((mapC: MapComponent) => { //the actual map renderer
        mapC.ready = true
        const route = L.polyline(coordinates)
        mapC.map = L.map(elt, {
          center: coordinates[0],
          //zoom: 1,
          layers: [
            L.tileLayer(
              'https://tiles1.sigma-dc-control.com/layer8/{z}/{x}/{y}.png',
              {
                attribution: mapC.theo('map_data'),
                minZoom: 10,
                maxZoom: 18,
                //tileSize: 512,
                //zoomOffset: -1
              }
            ),
            route,
            L.marker(
              coordinates[0],
              {
                icon: L.icon({
                  iconUrl: mapC.imagesOthers +'pin-start.png',
                  iconSize: [25, 37],
                  iconAnchor: [12, 37]
                })
              }
            ),
            L.marker(
              coordinates[len - 1],
              {
                icon: L.icon({
                  iconUrl: mapC.imagesOthers +'pin-end.png',
                  iconSize: [25, 37],
                  iconAnchor: [12, 37]
                })
              }
            )
          ]
        }).fitBounds(
          route.getBounds(),
          {
            maxZoom: 16,
            animate: true
          }
        )
        mapC.mask = true //activate the mask

        /**
         * bug fix: not all tiles showing after first rendering
         * @see https://github.com/Leaflet/Leaflet/issues/694
         * @deprecated @since PM (16.07.2020) because of the external setTimeOut
         *//*
        mapC.resize()
        */

        /**
         * resize the map on window resize
         * @see https://www.w3schools.com/jsref/event_onresize.asp
         * @see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
         */
        window.addEventListener('resize', () => mapC.resize())
        window.addEventListener('titleCollapse', (event: CustomEvent) => {
          if(event.detail.visible) mapC.resize()
        })
      }, this.millis, this)
    }
  }
}
