<!--
  this script is used to create the introduction HTML snippet
  @since PM (18.03.2020)
  @since ML (xx.07.2020) bootstrap classes & min-height
  @since PM (06.08.2020) switch between min-heights
-->
<div
  class="{{slug}} {{coordinates.length == 0 ? '' : 'nonempty'}} {{fullscreen ? flag : ''}}"
  (click)="$event.stopPropagation()"
>
  <div *ngIf="coordinates.length == 0" class="empty center">
    <img src="{{ imagesOthers }}logo.svg" >
    <p [innerHTML]="theo('gps_none')"></p>
  </div>
  <div *ngIf="coordinates.length > 0 && !ready" class="loader"></div>
  <div id="{{slug}}" class="empty"></div>

  <div
    *ngIf="map && mask && !fullscreen" class="full mask"
    (dblclick)="mask = !mask"
    (press)="mask = !mask"
  >
    <span class="center desktop" [innerHTML]="theo('map_notice_desktop')"></span>
    <span class="center mobile" [innerHTML]="theo('map_notice_mobile')"></span>
  </div>
  <button
    *ngIf="map"
    type="button" role="button" class="btn btn-secondary button-icon"
    (click)="toggle()"
  >
    <i class="icon-arrows-alt" title="{{theo(flag)}}"></i>
    <i class="icon-close" title="{{theo('close')}}"></i>
  </button>
</div>
