<!--
  this script is used to create the header HTML snippet
  @since PM (xx.06.2020)
  @see https://getbootstrap.com/docs/4.0/components/modal/#via-data-attributes
-->
<row-title
  [target]="target"
  [identifier]="head.identifier"
  [title]="head.title || theo(head.identifier)"
  [subtitle]="date"
  [namespace]="dataService.devModePM ? head.namespace : ''"
  [scroller]="scroller"
  [uppercase]="uppercase"
  [icon]="icon"
></row-title>
