<!--
  this script is used to create the introduction HTML snippet
  @since PM (18.03.2020)
-->
<!--
<div class="row {{slug}}">
  <div class="col">
    <span class="center">Pie chart 1</span>
  </div>
  <div class="col">
    <span class="center">Pie chart 2</span>
  </div>
</div>
-->
<div *ngIf="graph; else elseBlock" class="{{slug}} {{slug}}-steps">
  <apx-chart
    [legend]="noShow"
    [fill]="options.fill"
    [chart]="options.chart.steps"
    [plotOptions]="options.plotOptions.steps"
    [dataLabels]="options.dataLabels.steps"

    [series]="series.steps"
    [colors]="colors.steps"
    [labels]="labels.steps"
  ></apx-chart>
</div>
<div *ngIf="graph; else elseBlock" class="{{slug}} {{slug}}-times center">
  <apx-chart
    [legend]="noShow"
    [fill]="options.fill"
    [chart]="options.chart.times"
    [plotOptions]="options.plotOptions.times"
    [tooltip]="options.tooltip.times"
    [dataLabels]="options.dataLabels.times"

    [series]="series.times"
    [colors]="colors.times"
    [labels]="labels.times"
  ></apx-chart>
</div>
<i
  *ngIf="graph; else elseBlock"
  class="center icon-trophy {{slug}}-cup cup-{{graph.cup}}"
  style="color:{{colors.cup}}"
></i>
<ng-template #elseBlock>
  <div class="loader"></div>
</ng-template>
