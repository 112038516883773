import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Row} from '../../utils/elements';
import {Paragraph} from '../../utils/interfaces';

/**
 * @since PM (20.07.2020)
 * this component is a simple wrapper of the title.component;
 * its purpose is to simplify the theme development
 */
@Component({
  selector: 'row-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ParagraphComponent extends Row {
  readonly slug = 'paragraph'

  @Input() paragraph: Paragraph
  @Input() margin = true
}
