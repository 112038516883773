import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Section} from '../../utils/elements';
import {Data} from '../../utils/interfaces';
import {DownloadComponent} from '../download/download.component';
import {SettingsComponent} from '../settings/settings.component';

@Component({
  selector: 'section-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class HeaderComponent extends Section {

  @Input() set data(download: Data){
    if(download) this._download = Object.keys(download).length > 0
  }

  private _download = false
  readonly slug = 'header'
  readonly targets = {
    download: '#'+ DownloadComponent.slug,
    settings: '#'+ SettingsComponent.slug
  }


  get download(): boolean { return this._download }

  /**
   * this function is used to handle the settings-button in addition to the native bootstrap behavior
   */
  settingsHandler(){
    const elt: Element = this.eltOr(this.targets.settings)
    setTimeout(
      (active: string) => elt?.firstElementChild?.classList.add(active), //add the active class,
      100,
      this.active
    )
    //elt?.firstElementChild?.classList.add(this.active) //add the active class
  }
}
