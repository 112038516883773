import {ChangeDetectorRef, Component, ViewEncapsulation} from '@angular/core';
import {Page} from '../../utils/Page';
import {LanguageService} from '../../utils/language.service';

/**
 * this class is used to implement the data privacy page
 */
@Component({
  selector: 'app-root',
  templateUrl: './data-privacy.component.html',
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class DataPrivacyComponent extends Page {
  slug = 'data-privacy'
  constructor(public lang: LanguageService, protected changeDetector: ChangeDetectorRef) {
    super(lang, changeDetector);

    console.log(window.location)
  }
}
