import {Component, ViewEncapsulation} from '@angular/core';
import {Page, SectionIdentifier, ChartForm} from '../../utils/Page';
import {Chart, Data, Head, Intro} from '../../utils/interfaces';

/**
 * this script is used to create the theme logic
 * @since PM (xx.xx.2020)
 */
@Component({
  selector: 'app-root',
  templateUrl: './theme.component.html',
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ThemeComponent extends Page {
  slug = 'theme';
  readonly download: Data = {
    gpx: 'gpx.xml',
    kml: 'kml.xml',
    kmz: 'kmz.zip',
    slf: 'raw.slf'
  }
  readonly heads: Head[] = [
    this.head,
    {
      identifier: 'racing_bycicle',
      date: {
        year: 2020,
        month: 8,
        month_text_short: 'Aug',
        month_text_long: 'August',
        day: 11,
        day_text_short: 'Tue',
        day_text_long: 'Tuesday',
        hour: 10,
        minute: 52,
        second: 30,
      },
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'mountainbike',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'cycling',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'running',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'walking',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'hiking',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'treadmill',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'fitness',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'swimming',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'indoor_cycling',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'golf',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'inline_skates',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'ski',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'snowboarding',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'other',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'triathlon',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'ebike',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'bmx',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'cyclecross',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'enduro',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'activity',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'tracker',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    },
    {
      identifier: 'route',
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    }
  ]
  readonly intro: Intro[] = [
    {type: 'altitude', flag: 'altitude', label: 'altitude_uphill', value: '173500'},
    {type: 'calories', flag: 'calories', label: 'calories', value: 1345},
    {type: 'distance', flag: 'distance', label: 'distance', value: 47554},
    {type: 'heart_rate', flag: 'heart-rate', label: 'heart_rate_average', value: ''},
    {type: 'speed', flag: 'speed', label: 'speed_average', value: 7.472222222222221},
    {type: 'time', flag: 'time', label: 'time_training', value: '636400'},
    {type: 'steps', flag: 'steps', label: 'steps', value: '4500'},
    {type: 'categories', flag: 'categories', label: 'categories', value: ['flat', 'hilly', 'mountainous', 'none']},
    {type: 'rating', flag: 'rating', label: 'rating', value: 2},
    {
      type: 'sports', flag: 'sports', label: 'suitable_for',
      value: ['racing_bycicle', 'mountainbike', 'cycling', 'running', 'walking', 'hiking']
    },
  ]
  readonly col = 6
  // @ts-ignore
  readonly charts: Chart[] = [
    {
      type: 'altitude',
      colors: ['#248BB3'],
      points: {
        length: 6,
        labels: [0, 1000, 2000, 3000, 4000, 5000],
        lists: [[0, 1000, 2000, 3000, 4000, 5000]],
      },
      keys: ['#_differences_uphill', '#_differences_downhill', '#_minimum', '#_maximum'],
      statistics: {
        altitude_average: {
          value: 0,
          type: 'altitude'
        },
        altitude_differences_downhill: {
          value: 0,
          type: 'altitude'
        },
        altitude_differences_uphill: {
          value: 173500,
          type: 'altitude'
        },
        altitude_maximum: {
          value: 223000,
          type: 'altitude'
        },
        altitude_minimum: {
          value: 148000,
          type: 'altitude'
        }
      }
    },
    {
      type: 'speed',
      colors: ['#1a817c'],
      points: {
        length: 6,
        labels: [0, 1000, 2000, 3000, 4000, 5000],
        lists: [[0, 0, 0, 0, 0, 0]],
      },
      keys: ['#_average', '#_maximum'],
      statistics: {
        speed_average: {
          value: 7.47,
          type: 'speed'
        },
        speed_average_downhill: {
          value: 0,
          type: 'speed'
        },
        speed_average_uphill: {
          value: 0,
          type: 'speed'
        },
        speed_maximum: {
          value: 11,
          type: 'speed'
        },
        speed_minimum: {
          value: 0,
          type: 'speed'
        }
      }
    },
    {
      type: 'heart_rate',
      colors: ['#ca2a3d'],
      points: {
        length: 6,
        labels: [0, 1000, 2000, 3000, 4000, 5000],
        lists: [[0, 0, 0, 0, 0, 0]],
      },
      keys: ['#_average', '#_minimum', '#_maximum'],
      statistics: {
        heart_rate_average: {
          value: 0,
          type: 'heart_rate'
        },
        heart_rate_average_percent: {
          value: 0,
          type: 'percent'
        },
        heart_rate_maximum: {
          value: 0,
          type: 'heart_rate'
        },
        heart_rate_minimum: {
          value: 0,
          type: 'heart_rate'
        }
      }
    },
    {
      type: 'cadence',
      colors: ['#934A3B'],
      points: {
        length: 0,
        labels: [0],
        lists: [[999999]],
      },
      keys: ['#_average', '#_maximum'],
      statistics: {
        cadence_average: {
          value: 0,
          type: 'cadence'
        },
        cadence_maximum: {
          value: 0,
          type: 'cadence'
        },
        cadence_minimum: {
          value: 0,
          type: 'cadence'
        }
      }
    },
    {
      type: 'power',
      colors: ['#C71232'],
      points: {
        length: 6,
        labels: [0, 1000, 2000, 3000, 4000, 5000],
        lists: [[0, 1, 2, 3, 4, 5]],
      },
      keys: ['#_average', '#_maximum'],
      statistics: {
        power_average: {
          value: 0,
          type: 'power'
        },
        power_average_watt_per_kg: {
          value: 0,
          type: 'ratio'
        },
        power_maximum: {
          value: 0,
          type: 'power'
        },
        power_minimum: {
          value: 0,
          type: 'power'
        },
        work: {
          value: 0,
          type: 'work'
        }
      }
    },
    {
      type: 'temperature',
      colors: ['#A2325D'],
      points: {
        length: 6,
        labels: [0, 1000, 2000, 3000, 4000, 5000],
        lists: [[0, 1, 2, 3, 4, 5]],
      },
      keys: ['#_average', '#_minimum', '#_maximum'],
      statistics: {
        temperature_average: {
          value: 17.6,
          type: 'temperature'
        },
        temperature_maximum: {
          value: 25,
          type: 'temperature'
        },
        temperature_minimum: {
          value: 16,
          type: 'temperature'
        }
      }
    },
    {
      type: 'battery',
      colors: ['#399D57'],
      points: {
        length: 6,
        labels: [0, 1000, 2000, 3000, 4000, 5000],
        lists: [[0, 1, 2, 3, 4, 5]],
      },
      keys: ['#_level_last'],
      statistics: {
        battery_level_first: {
          value: 0,
          type: 'percent'
        },
        battery_level_last: {
          value: 0,
          type: 'percent'
        }
      }
    },
    {
      type: 'assist',
      form: ChartForm.bar,
      colors: [
        ['#000', '#23ff00', '#ff3219'],
        ['#000', '#23ff00', '#ff3219'],
        ['#000', '#23ff00', '#ff3219'],
        ['#000', '#1464ff', '#23ff00', '#ff3219'],
        ['#000', '#1464ff', '#23ff00', '#fe2ac2', '#ff3219'],
        ['#000', '#00e6ff', '#1464ff', '#23ff00', '#fe2ac2', '#ff3219'],
        ['#000', '#00e6ff', '#1464ff', '#23ff00', '#fe2ac2', '#ff3219', '#ff3219'],
        ['#000', '#00e6ff', '#1464ff', '#23ff00', '#fe2ac2', '#fe2ac2', '#ff3219', '#ff3219'],
        ['#000', '#00e6ff', '#1464ff', '#23ff00', '#23ff00', '#fe2ac2', '#fe2ac2', '#ff3219', '#ff3219'],
        ['#000', '#00e6ff', '#1464ff', '#1464ff', '#23ff00', '#23ff00', '#fe2ac2', '#fe2ac2', '#ff3219', '#ff3219']
      ],
      points: {
        length: 6,
        labels: [0, 1, 2, 3, 4],
        lists: [[3090035, 6504026, 4763817, 5776133, 4374405]],
      },
      keys: ['#_mode_0', '#_mode_1', '#_mode_2', '#_mode_3', '#_mode_4', '#_mode_5', '#_mode_6', '#_mode_7', '#_mode_8', '#_mode_9'],
      statistics: {
        assist_available: {
          value: 0,
          type: 'assist'
        },
        assist_mode_0: {
          value: 34000000,
          type: 'assist'
        },
        assist_mode_1: {
          value: 29000000,
          type: 'assist'
        },
        assist_mode_2: {
          value: 48250000,
          type: 'assist'
        },
        assist_mode_3: {
          value: 30952630,
          type: 'assist'
        },
        assist_mode_4: {
          value: null,
          type: 'assist'
        },
        assist_mode_5: {
          value: null,
          type: 'assist'
        },
        assist_mode_6: {
          value: null,
          type: 'assist'
        },
        assist_mode_7: {
          value: null,
          type: 'assist'
        },
        assist_mode_8: {
          value: null,
          type: 'assist'
        },
        assist_mode_9: {
          value: null,
          type: 'assist'
        },
        assist_mode_: {
          value: 30952630,
          type: 'assist'
        }
      }
    },
  ].map(
    // @ts-ignore
    (chart: Chart) => {
    chart.grid =
      chart.points.length > 0 &&
      chart.points.lists.length > 0 &&
      chart.points.lists //the sum of all values across all lists
        .map(list => list.reduce((prev, cur) => prev + Math.abs(cur)))
        .reduce((prev, cur) => prev + Math.abs(cur)) > 0
    chart.stats = Object.keys(chart.statistics).length > 0 && Object.keys(chart.statistics)
      .map(key => chart.statistics[key].value as number)
      .reduce((prev, cur) => prev + Math.abs(cur)) > 0

    return chart
  }).filter(chart => chart.grid || chart.stats)
  readonly config = {
    intro: ['altitude_uphill', 'calories', 'distance', 'heart_rate', 'speed', 'time'],
    laps: {
      number: ['value'],
      distance: ['value'],
      time: ['value', 'absolute'],
      speed: ['average']
    }
  }
  readonly laps = [
    {
      key: SectionIdentifier.lapsReg,
      identifier: this.kebabise(SectionIdentifier.lapsReg),
      rounds: [
        {
          altitude: {
            average: '167493',
            downhill: null,
            uphill: '173000',
            maximum: '223000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '1344.9999999999823',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 47554,
            value: 47554,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1.376068376068376',
            average_uphill: '1.3789473684210527',
            maximum_downhill: '-3',
            maximum_uphill: '4',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 1,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.47,
            minimum: 0,
            maximum: 11,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '636400',
            absolute: '636400',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        }
      ]
    },
    {
      key: SectionIdentifier.lapsAuto,
      identifier: this.kebabise(SectionIdentifier.lapsAuto),
      rounds: [
        {
          altitude: {
            average: '207793',
            downhill: null,
            uphill: '10000',
            maximum: '223000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '138.08385579937323',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 5000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1.5892857142857142',
            average_uphill: '1',
            maximum_downhill: '-3',
            maximum_uphill: '1',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 1,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.71,
            minimum: 0,
            maximum: 10.96,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '65010.416666666664',
            absolute: '65010.416666666664',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '171704',
            downhill: null,
            uphill: '15000',
            maximum: '184000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '131.75940438871498',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 10000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1.2941176470588236',
            average_uphill: '1.1818181818181819',
            maximum_downhill: '-3',
            maximum_uphill: '3',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 2,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.96,
            minimum: 0,
            maximum: 10.3,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '62795.70578231292',
            absolute: '127806.12244897959',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '154595',
            downhill: null,
            uphill: '9000',
            maximum: '162000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '132.8134796238247',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 15000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1.5',
            average_uphill: '1',
            maximum_downhill: '-3',
            maximum_uphill: '1',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 3,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.99,
            minimum: 0,
            maximum: 11,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '62535.340965654555',
            absolute: '190341.46341463414',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '152177',
            downhill: null,
            uphill: '4000',
            maximum: '154000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '130.70532915360528',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 20000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1',
            average_uphill: '0',
            maximum_downhill: '-1',
            maximum_uphill: '0',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 4,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 8.05,
            minimum: 0,
            maximum: 9.13,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '62145.02307185234',
            absolute: '252486.48648648648',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '156887',
            downhill: null,
            uphill: '14000',
            maximum: '160000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '159.16536050156736',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 25000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1.4375',
            average_uphill: '1.7333333333333334',
            maximum_downhill: '-2',
            maximum_uphill: '2',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 5,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 6.73,
            minimum: 0,
            maximum: 9.97,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '74342.08494208494',
            absolute: '326828.5714285714',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '155136',
            downhill: null,
            uphill: '7000',
            maximum: '157000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '146.51645768025088',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 30000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1',
            average_uphill: '1.375',
            maximum_downhill: '-1',
            maximum_uphill: '3',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 6,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.21,
            minimum: 0,
            maximum: 8.75,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '69360.61776061775',
            absolute: '396189.1891891892',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '151383',
            downhill: null,
            uphill: '10000',
            maximum: '153000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '140.19200626959264',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 35000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1.4',
            average_uphill: '1.2222222222222223',
            maximum_downhill: '-2',
            maximum_uphill: '2',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 7,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.52,
            minimum: 0,
            maximum: 9.59,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '66580.81081081083',
            absolute: '462770',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '157485',
            downhill: null,
            uphill: '36000',
            maximum: '168000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '145.46238244514117',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 40000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1.4516129032258065',
            average_uphill: '1.4878048780487805',
            maximum_downhill: '-3',
            maximum_uphill: '4',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 8,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.21,
            minimum: 0,
            maximum: 9.93,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '69230',
            absolute: '532000',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        },
        {
          altitude: {
            average: '178281',
            downhill: null,
            uphill: '50000',
            maximum: '205000',
            label: 'altitude',
            type: 'altitude'
          },
          cadence: {
            average: null,
            maximum: null,
            label: 'cadence',
            type: 'cadence'
          },
          calories: {
            value: '142.30015673981205',
            label: 'calories',
            type: 'calories'
          },
          distance: {
            absolute: 45000,
            value: 5000,
            label: 'distance',
            type: 'distance'
          },
          duration: {
            value: '0',
            label: 'duration',
            type: 'duration'
          },
          heart_rate: {
            average: null,
            minimum: null,
            maximum: null,
            label: 'heart_rate',
            type: 'heart_rate'
          },
          incline: {
            average_downhill: '-1',
            average_uphill: '1.56',
            maximum_downhill: '-1',
            maximum_uphill: '3',
            label: 'incline',
            type: 'incline'
          },
          number: {
            value: 9,
            label: 'number',
            type: 'number'
          },
          power: {
            average: null,
            maximum: null,
            label: 'power',
            type: 'power'
          },
          speed: {
            average: 7.43,
            minimum: 0,
            maximum: 9.09,
            label: 'speed',
            type: 'speed'
          },
          time: {
            value: '67296.875',
            absolute: '599296.875',
            downhill: null,
            uphill: null,
            label: 'time',
            type: 'time'
          }
        }
      ]
    }
  ]
}
