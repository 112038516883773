import {Component, ViewEncapsulation} from '@angular/core';
import Resource from '../resource/resource';
import {Graph, GraphColors, Intro} from '../../utils/interfaces';

/**
 * @example https://meppe.sigma-sharing.de.srv05.24743.serviceprovider.de/view/tracker/55b2f270a0f824151a7ad78e7dc54573195464a8
 */

@Component({
  selector: 'app-root',
  templateUrl: './resource.tracker.component.html',
  styleUrls: ['./resource.tracker.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ResourceTrackerComponent extends Resource {
  private _graph: Graph
  private _colors: GraphColors


  get graph(): Graph { return this._graph }
  get colors(): GraphColors { return this._colors }


  protected custom(data: any, config: any){
    this._graph = data.graph
    this._colors = config.graph.colors
  }
}
