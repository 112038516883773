import {Component, ViewEncapsulation} from '@angular/core';
import {Page} from '../../utils/Page';

/**
 * DO NOT REMOVE this class it is required to enable nested routing
 * @see https://www.techiediaries.com/angular/angular-9-nested-routing-child-routes-example
 */
@Component({
  selector: 'app-root',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class HomeComponent extends Page {
  slug = 'home'
}
