<!--
  this script is used to create the header HTML snippet
  @since ML (xx.06.2020)
  @see https://getbootstrap.com/docs/4.0/components/modal/#via-data-attributes
-->
<div class="row mb-3 mt-1 {{slug}} {{slug}}-color color-{{flag}}">
  <div *ngIf="icon" class="col-2 col-sm-2 col-md-1 icon mt-2 mb-2 mt-sm-2 mb-sm-2 mt-md-2 mt-lg-3 mb-lg-3">
    <img
      *ngIf="identifier"
      src="{{imagesDir}}{{flag}}.svg"
      class="icon-h "
      title="{{theo(identifier)}}"
    >
  </div>
  <div class="{{
    (target && namespace ?
      (icon ? 'col-8 col-sm-10' : 'col-10 col-sm-12') :
      (icon ? 'col-10 col-sm-10 col-md-11' : 'col-12 col-sm-12 col-md-12')
    )
  }}">
    <div *ngIf="identifier" class="{{slug}}-primary mt-2 mb-2 mt-sm-2 mt-md-2-title mt-lg-3 mb-sm-2 mb-lg-3">
      <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 ml-0 mr-0">
        <div class="col col-md-auto ml-lg-0 pl-0">
          <h2
            class="font-color {{uppercase ? 'text-uppercase' : ''}}"
            [innerHTML]="title || theo(identifier)"
          ></h2>
        </div>
        <div *ngIf="subtitle" class="col col-md-auto p-0 mt-2 mt-md-0">
          <h2
            class="{{slug}}-secondary font-color"
            [innerHTML]="subtitle"
          ></h2>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="target && namespace" class="col-2 col-sm-1">
    <button
      type="button" data-toggle="collapse" role="button"
      class="btn btn-secondary button-icon toggle toggle-{{visible ? 'up' : 'down'}}"
      title="{{theo(visible ? 'hide' : 'show')}}"
      [attr.data-target]="target"
      [attr.aria-expanded]="visible ? 'true' : 'false'"
      [attr.aria-controls]="target"
      (click)="collapse()"
    >
      <i class="{{visible ? 'icon-arrow-up' : 'icon-arrow-down'}}"></i>
    </button>
  </div>
</div>
