import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Section} from '../../utils/elements';
import {Data} from '../../utils/interfaces';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../utils/data.service';

/**
 * this script is used to create the !!! logic
 * @since PM (xx.xx.2020)
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 * https://apexcharts.com/docs/options/title/
 */
@Component({
  selector: 'modal-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class DownloadComponent extends Section {
  static slug = 'download'
  readonly slug = DownloadComponent.slug
  private _data: Data = {}
  private _keys: string[] = []
  private _list: Data<boolean> = {}
  private _inactive = true //this property is used to control the state of the button
  private _downloading = false

  @Input() identifier: string
  @Input() set data(data: Data){
    if(data){
      this._data = data
      this._keys = Object.keys(data)
      this._keys.forEach(key => this._list[key] = false)
    }
  }


  constructor(private http: HttpClient) {
    super();
  }


  get keys(): string[] { return this._keys }
  get list(): Data<boolean> { return this._list }
  get inactive(): boolean { return this.identifier === '' || this._inactive }
  get downloading(): boolean { return this._downloading }

  /**
   * this function is used to handle the close event
   * @param event
   */
  close(event: Event){

  }

  /**
   * this function is used to handle the update event
   * @param key
   */
  update(key: string){
    this._list[key] = !this._list[key]
    this._inactive = this._keys.filter(key2 => this._list[key2]).length === 0
  }

  /**
   * this function is used to handle the download event
   * @param event
   */
  download(event: Event){
    const elt = this.eltEv<HTMLButtonElement>(event)
    if(elt?.disabled || elt?.classList.contains(this.disabled)) return;

    this._downloading = true
    window.open(`server/downloader.php?identifier=${this.identifier}&files=${this._keys.filter(key => this._list[key]).map(key => this._data[key]).join(',')}`)
    this._downloading = false
  }
}
