import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ChangeDetectorRef} from '@angular/core';
import {LanguageService} from '../../utils/language.service';
import {Page} from '../../utils/Page';
import {Config, Head, Intro, Data, DateObject, Chart, Points, Statistics} from '../../utils/interfaces';
import {Units} from '../../utils/units';
import {Thesaurus} from '../../thesauri/thesaurus';
import {DataService} from '../../utils/data.service';

/**
 * @todo document the class
 */
export default abstract class Resource extends Page {
  slug = 'resource'
  private _identifier: string|null
  private _done: boolean
  private _error = ''
  private _config: Config
  private _resource = Thesaurus._
  private _head: Head = {identifier: '', title: '', date: {}, config: {}, namespace: ''}
  private _intro: Intro[] = []
  private _download: Data
  private _col: number //chart's column
  protected _coordinates: number[][] = []
  protected _charts: Chart[] = []


  protected abstract custom(data: any, config: any);


  constructor(
    public lang: LanguageService, protected changeDetector: ChangeDetectorRef,
    private route: ActivatedRoute, private http: HttpClient
  ){
    super(lang, changeDetector)
    Units.set()
  }

  /**
   * @see https://www.tektutorialshub.com/angular/angular-pass-data-to-route/
   */
  ngOnInit() {
    super.ngOnInit()
    this.route.data.subscribe(
      data => this.fetch(
        this.route.snapshot.paramMap.get('identifier'), //NULL if undefined
        data.debug || DataService.devModePM
      ),
      () => this.fetch(
        this.route.snapshot.paramMap.get('identifier'), //NULL if undefined
        DataService.devModePM
      )
    )
  }

  ngAfterViewInit() {
    super.ngAfterViewInit()

    //listen to clicks outside the map element & reset the map mask by proxy
    document.documentElement.addEventListener(
      'click',
      () => window.dispatchEvent(new CustomEvent('mask_map'))
    )

    //TODO fix the bug
    //this.eltJ('[data-toggle][title]').tooltip()
  }


  get identifier(): string|null { return this._identifier }
  get done(): boolean { return this._done }
  get error(): string { return this._error }
  get config(): Config { return this._config }
  get resource(): string { return this._resource }
  get head(): Head { return this._head }
  get intro(): Intro[] { return this._intro }
  get download(): Data { return this._download }
  get coordinates(): number[][] { return this._coordinates }
  get charts(): Chart[] { return this._charts }
  get col(): number { return this._col }


  /**
   * this function is used to the set the charts according to the given data & configuration
   * @since PM (06.08.2020) standalone
   * @final
   * @param config
   * @param charts
   * @param statistics
   */
  private setCharts(config: {}, charts: {}, statistics: {}){
    let conf: any, points: Points|null, stats: Statistics, valid: {grid: boolean, stats: boolean}
    Object.keys(config).forEach((type: string) => {
      conf = config[type]
      points = charts[type]
      stats = statistics[type] || {}

      if(conf && points){
        const
          statsKeys = `|${conf.statistics.join('|').replace(/#_/g, type +'_')}|`,
          statsFiltered = Object.keys(stats).filter(key => statsKeys.includes(`|${key}|`)) //only check the keys whose values are supposed to be presented

        valid = { //validate the presence of the grid & the stats
          grid: points.length > 0 &&
            points.lists.length > 0 &&
            points.lists //the sum of all values across all lists
              .map(list => list.reduce((prev, cur) => prev + Math.abs(cur)))
              .reduce((prev, cur) => prev + Math.abs(cur)) > 0,
          stats: Object.keys(statsFiltered).length > 0 && Object.keys(statsFiltered)
            .map(key => statsFiltered[key].value as number)
            .reduce((prev, cur) => prev + Math.abs(cur)) > 0
        }
        if(valid.grid || valid.stats) this._charts.push({
          type,
          form: conf.form,
          colors: conf.colors,
          points,
          keys: conf.statistics,
          statistics: stats,

          grid: valid.grid,
          stats: valid.stats
        });
      }
    })
  }


  /**
   * @description this method is used to fetch the data from the server
   * @param identifier
   * @param debug
   */
  private fetch(identifier: string, debug: boolean) {
    this._identifier = identifier

    this.http
      //.post(window.location.href +"server/data.php", options)
      .post('server/loader.php', {identifier, debug})
      .subscribe(
        (response: any) => {
          if(DataService.devMode) console.log(response)

          this._done = true
          if('error' in response){
            const error = response.error.trim();
            this._error = error.includes(' ') ? error : LanguageService.theo.errors[error];
          }else{
            this._config = response.config //should come first

            const data = response.data;
            this._resource = data.slug;
            this._download = data.download

            //head & intro
            const intro = data.intro
            this._head = {
              identifier: this.underscore(intro.identifier),
              title: intro.name,
              date: intro.date as DateObject, //'as DateObject' simple security measure
              config: this._config.date,
              namespace: identifier
            }
            this._config.intro.forEach((type) => {
              const temp = intro.values[type]
              if(temp){
                temp.flag = this.kebabise(temp.type)
                this._intro.push(temp)
              }
            })

            //coordinates, charts & statistics
            this._coordinates = data.coordinates
            this.setCharts(this._config.charts, data.charts, data.statistics || {})

            //final block
            this.custom(data, this._config)
            this._col = this._charts.length > 1 ? 6 : 12
          }
        },
        error => {
          console.log(DataService.devMode, error)
          this._done = true;
          this._error = error;
        },
        () => { if(DataService.devMode) console.log('DONE!!!') }
      );
  }
}
