import {Pipe, PipeTransform} from '@angular/core';
import {Strimber} from './interfaces';
import {Unit, Units} from './units';
import {LanguageService} from './language.service';
import {min} from 'rxjs/operators';

/**
 * @see https://angular.io/guide/pipes
 * @since PM (07.07.2020)
 */
export class Values {
  /**
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_objects/Number/toPrecision
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/toFixed
   */
  private static precision = 2


  /**
   * this function is used to parse the value
   * @param type
   * @param value
   * @param precision
   */
  static parse(type: string, value: Strimber|string[], precision: number = Values.precision): string {
    switch(type){
      case 'altitude':
      case 'altitude'+ Units.label:
        if(Units.altitude === Unit.m) return this.kilo(value as number, 0) //mm to m
        else if(Units.altitude === Unit.ft) return this.mm2ft(value as number, 0)
        break
      case 'assist_mode':
      case 'assist_mode'+ Units.label:
        return LanguageService.text('mode') +' '+ value
      case 'speed':
      case 'speed'+ Units.label:
        if(Units.speed === Unit.kmh) return this.ms2kmh(value as number, precision)
        else if(Units.speed === Unit.mph) return this.ms2mph(value as number, precision)
        break
      case 'temperature':
      case 'temperature'+ Units.label:
        return Units.temperature === Unit.f ?
          this.c2f(value as number, 1) :
          this.round(value as number, 1)
      case 'time':
      case 'time'+ Units.label:
        return this.hs2h(value as number, precision)
      case 'time_h':
      case 'time_h'+ Units.label:
        return this.hs2h(value as number, 0)
      //...
      case 'assist':
      case 'assist'+ Units.label:
      case Units.dist:
      case Units.dist + Units.label:
        return this.parseD(value as string, precision)
      case 'battery':
      case 'battery'+ Units.label:
      case 'calories':
      case 'calories'+ Units.label:
      case 'cadence':
      case 'cadence'+ Units.label:
      case 'heart_rate':
      case 'heart_rate'+ Units.label:
      case 'power':
      case 'power'+ Units.label:
      case 'steps':
      case 'steps'+ Units.label:
        return this.round(value as number, 0)
      case 'time_hm':
      case 'time_hm'+ Units.label:
      case 'time_y'+ Units.label:
        return this.hs2h(value as number, 1)
    }
    //DO NOT MERGE
    //cadence, number, percent, time_x, time_y
    return value.toString()
    //return this.round(value as string, precision)
  }

  /**
   * this function is used to parse the distance
   * @param value
   * @param precision
   */
  static parseD(value: Strimber, precision: number = Values.precision): string {
    if(Units.distance === Unit.km) return this.kilo(value as number, precision)
    else if(Units.altitude === Unit.ft) return this.m2mi(value as number, precision)
    else return this.round(value, precision)
  }


  private static kilo(value: number, precision: number = Values.precision): string {
    return this.round(value / 1000, precision)
  }
  private static mm2ft(value: number, precision: number = Values.precision): string {
    return this.round(value * .00328084, precision)
  }
  private static m2mi(value: number, precision: number = Values.precision): string {
    return this.round(value * .000621371, precision)
  }
  private static ms2kmh(value: number, precision: number = Values.precision): string {
    return this.round(value * 3.6, precision)
  }
  private static ms2mph(value: number, precision: number = Values.precision): string {
    return this.round(value * 2.23694, precision)
  }
  private static c2f(value: number, precision: number = Values.precision): string {
    return this.round(value * 9 / 5 + 32, precision)
  }
  private static hs2h(value: number, precision: number = Values.precision): string { //hundredth of a second to hour
    value = value / 100 //amount of seconds
    const h = Math.floor(value / 60 / 60)

    value = value - h * 60 * 60 //amount of minutes remaining in seconds
    const
      m = Math.floor(value / 60),
      s = Math.round(value - m * 60) //round because of the lap time

    const
      hour = (h < 10 ? '0' : '') + h,
      minutes = (m < 10 ? '0' : '') + m,
      seconds = (s < 10 ? '0' : '') + s

    return precision === 0 ?
      hour : (precision === 1 ?
        `${hour}:${minutes}` :
        `${hour}:${minutes}:${seconds}`
      )
  }
  private static hs2hY(value: number, precision: number = Values.precision): string { //hundredth of a second to hour for the y-axis
    return this.round(
      value / 100 /*= amount of seconds*/ / 60 /*= amount of minutes*/ / 60 /*= amount of hours*/,
      precision
    )
  }

  /**
   * this function is used to round & normalise the given value
   * @since PM (29.09.2020) the rounding algorithm has been rewritten; the results are now closer to those of the *Data Center*
   * @param value
   * @param precision
   * @return parsed value
   */
  private static round(value: Strimber, precision: number): string {
    const
      //temp = Number(value).toFixed(precision),
      factor = Math.pow(10, precision),
      temp = String(Math.floor(Number(value) * factor) / factor),
      pieces = temp.split('.')

    if(precision > 0) pieces[0] += LanguageService.theo.digits.decimal
    if(!pieces[1]) pieces[1] = ''
    const lim = precision - pieces[1].length
    for (let i = 0; i < lim; i++) pieces[1] += '0'

    return pieces[0] + pieces[1]
  }

  /**
   * @deprecated because of the external dependencies the loop is done in HTML now
   * @param list
   * @param callback
   * @param glue
   */
  private static parseList(list: Strimber[], callback: (item: Strimber) => string, glue = ''): string {
    return list.map(item => callback(item)).join(glue)
  }
}

/**
 *
 */
@Pipe({ name: 'parseValue' })
export class ValuePipe implements PipeTransform {
  /**
   *
   * @param type
   * @param value @note values that are originally in array are implicitly transformed to a comma separated string list
   * @param args
   */
  transform(type: string, value: Strimber, ...args: any[]): Strimber {
    return Values.parse(type, value || 0)
  }
}

