import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Row} from '../../utils/elements';
import {SectionIdentifier} from '../../utils/Page';

@Component({
  selector: 'row-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class TitleComponent extends Row {
  private _identifier: string
  private _flag: string
  private _target: string //the target is located OUTSIDE the title
  private _namespace: string|null = null
  private key = '' //the storage key
  private _imagesDir = this.imagesSections
  public visible = true


  @Input()
  set target(target: string){
    if(target) this._target = 'div.row#'+ target
  }
  get target(): string { return this._target }

  @Input()
  set identifier(identifier: string){
    this._identifier = identifier
    this._flag = this.kebabise(identifier)
    if(identifier){
      if(!this._target) this._target = 'div.row#'+ identifier

      const ids = [
        SectionIdentifier.overview,
        SectionIdentifier.charts,
        SectionIdentifier.lapsReg,
        SectionIdentifier.lapsAuto
      ]
      if( !identifier.match(new RegExp(`^${ids.join('|')}$`)) ) this._imagesDir = this.imagesDiscTrans
    }
  }
  get identifier(): string { return this._identifier }

  @Input() title = ''
  @Input() subtitle = ''

  @Input() //the resource identifier or 'theme' for the theme
  set namespace(namespace: string|null){
    this._namespace = namespace
    this.key = this._identifier +'_'+ this._namespace
    this.initialise()
  }
  get namespace(): string|null { return this._namespace }

  @Input() scroller?: any
  @Input() uppercase = false
  @Input() icon = true


  readonly slug = 'title'

  get imagesDir(): string { return this._imagesDir }
  get flag(): string { return this._flag }


  /**
   * this function is used to update the [title & row] combination;
   * it is a work around to the native bootstrap event handlers;
   * the actual collapse of the DOM elements is done implicitly by bootstrap
   * @see https://getbootstrap.com/docs/4.5/components/collapse
   * @see https://www.geeksforgeeks.org/how-to-animate-scrolltop-using-jquery
   */
  collapse(){
    this.visible = !this.visible
    localStorage.setItem(this.key, this.visible ? '1' : '0')

    if(this.visible){
      //@see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
      window.dispatchEvent(new CustomEvent(
        'titleCollapse',
        {detail: {visible: this.visible}}
      ))
      if(this.scroller && this.eltOr(this._target)) this.scroller.animate({
        scrollTop: this.eltJ(this._target).parents('section').offset().top //scroll to the section
      })
    }
  }


  /**
   * @see https://getbootstrap.com/docs/4.5/components/collapse/#methods
   */
  private initialise(){
    const temp = localStorage.getItem(this.key)
    this.visible = !temp || temp === '1'

    const interval = setInterval(
      (title: TitleComponent) => {
        const elt = title.eltOr(title.target)
        if(elt){
          clearInterval(interval)
          const cl = 'collapse', classList = elt.classList

          /**
           * @deprecated @since PM (21.07.2020)
           * - for some reason the bootstrap event handlers isn't working
           * - hence the function `this.collapse` as work around
           * @see https://getbootstrap.com/docs/4.5/components/collapse/#events
           *//*
          title.eltJ(elt)
            .on('show.bs.'+ cl, () => localStorage.setItem(this.key, '1'))
            .on('hide.bs.'+ cl, () => localStorage.setItem(this.key, '0'))
          */

          /**
           * the functions .collapse('show') & .collapse('hide') are avoided here on purpose
           * @see https://getbootstrap.com/docs/4.5/components/collapse/#methods
           */
          if(title.visible) classList.add(cl, 'show')
          else{
            classList.add(cl)
            classList.remove('show')
          }
        }
      },
      this.millis/2,
      this
    )
  }
}
