import {Component, Input, ViewEncapsulation} from '@angular/core';
import {ChartForm} from '../../utils/Page';
import {Graph, GraphColors} from '../../utils/interfaces';
import Diagram from '../../utils/Diagram';
import {Values} from '../../utils/values';
import {Unit} from '../../utils/units';

/**
 * this script is used to create the chart line logic
 * @since PM (xx.xx.2020)
 */
@Component({
  selector: 'column-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class GraphComponent extends Diagram {
  @Input()
  set colors(colors: GraphColors){ this._colors = colors }
  get colors(): GraphColors { return this._colors }

  @Input()
  set graph(graph: Graph){
    if(graph){
      const interval = setInterval(() => {
        if(this.ready){
          clearInterval(interval)
          this.set(graph)
          //SHOULD NOT MERGE|SWITCH
          this._graph = graph
        }
      }, this.millis)
    }
  }
  get graph(): Graph { return this._graph }

  slug = 'graph'
  readonly options = { //https://apexcharts.com/javascript-chart-demos/pie-charts/gradient-donut/
    chart: {
      steps: {
        type: ChartForm.donut,
        height: '300px'
      },
      times: {
        type: ChartForm.donut,
        height: '200px' //bug fix: empiric value
      }
    },
    fill: {
      type: 'solid',
    },
    dataLabels: {steps: {} as any, times: {} as any},
    /**
     * @see https://apexcharts.com/docs/options/plotoptions/pie/
     * @see https://apexcharts.com/javascript-chart-demos/radialbar-charts/custom-angle-circle/
     */
    plotOptions: {
      steps: {
        pie: {
          startAngle: 180,
          donut: {}
        }
      },
      times: {
        pie: {
          startAngle: 180,
          donut: {
            size: '40%',
          }
        }
      }
    },
    tooltip: { //@see https://apexcharts.com/docs/options/tooltip/
      steps: {

      },
      times: {
        style: {
          fontSize: '.8em' //correcting the down scaling of 1.1
        },
        y: {
          formatter: time => Values.parse('time_hm', time) +' '+ Unit.h,
        }
      }
    }
  }
  //readonly color = this.sigmaRed //the default color
  private _series: {steps: number[], times: number[]}
  private _colors: GraphColors
  private _labels: {steps: string[], times: string[]}
  private _graph: Graph


  get series(): {steps: number[], times: number[]} { return this._series }
  get labels(): {steps: string[], times: string[]} { return this._labels }

  /**
   * this function is used to set
   * @param graph
   */
  set(graph: Graph){
    const
      series = [], colors = [], labels = [],
      label = this.theo('steps'), half = graph.goal / 2,
      colorRem = '#bfbfbf' //#a6a6a6
    if(graph.steps > half){
      series.push(half)
      colors.push(this._colors.steps[0] || this.sigmaRed)
      labels.push(label)

      const quarter = graph.goal / 4
      if(graph.steps > half + quarter){
        series.push(quarter)
        colors.push(this._colors.steps[1] || this.sigmaRed)
        labels.push(label)

        if(graph.steps > graph.goal){
          series.push(graph.goal - half - quarter)
          colors.push(this._colors.cup = this._colors.steps[2] || this.sigmaRed)
          labels.push(label)
        }else{
          series.push(graph.steps - half - quarter, graph.goal - graph.steps) //blank space at the end
          colors.push(
            this._colors.cup = this._colors.steps[2] || this.sigmaRed,
            colorRem
          )
          labels.push(label, this.theo('steps_remaining'))
        }
      }else{
        series.push(graph.steps - half, graph.goal - graph.steps) //blank space at the end
        colors.push(
          this._colors.cup = this._colors.steps[1] || this.sigmaRed,
          colorRem
        )
        labels.push(label, this.theo('steps_remaining'))
      }
    }else{
      series.push(graph.steps, graph.goal - graph.steps) //steps made + blank space
      colors.push(
        this._colors.cup = this._colors.steps[0] || this.sigmaRed,
        colorRem
      )
      labels.push(label, this.theo('steps_remaining'))
    }

    this.options.dataLabels = { //@see https://apexcharts.com/docs/options/datalabels/
      steps: {
        formatter: (_, {seriesIndex}) => {
          const steps = this._series.steps[seriesIndex]
          return `${steps > 999 ? Math.floor(steps / 1000) +'k' : steps}`
        },
        style: {
          fontFamily: 'Roboto, sans-serif',
          colors: ['#ececec'],
          fontSize: '1.25em'
        },
        background: {
          dropShadow: this.noShadow
        },
        dropShadow: this.noShadow
      },
      times: {
        formatter: (_, {seriesIndex}) => Values.parse('time_hm', this._series.times[seriesIndex]) +' '+ Unit.h,
        style: {
          fontFamily: 'Roboto, sans-serif',
          colors: ['#ececec'],
          fontSize: '.9em'
        },
        background: {
          dropShadow: this.noShadow
        },
        dropShadow: this.noShadow
      }
    }
    this._series = {
      steps: series,
      times: [graph.low, graph.middle, graph.high]
    }
    this._colors.steps = colors
    this._labels = {
      steps: labels,
      times: ['low', 'middle', 'high'].map(suffix => this.theo('time_'+ suffix))
    }
  }
}
