<!--
  this script is used to create the introduction HTML snippet
  @since PM (18.03.2020)
-->
<table class="{{slug}} {{slug}}-{{identifier}}">
  <thead>
    <th *ngFor="let label of labels" [innerHTML]="label"></th>
  </thead>
  <tbody>
    <tr *ngFor="let line of lines">
      <td *ngFor="let entry of line" class="{{entry.identifier}}" [attr.data-label]="entry.label" >{{entry.value}}</td>
    </tr>
  </tbody>
</table>
