import { Component } from '@angular/core';
import { Element } from './utils/elements'
import {Thesaurus} from './thesauri/thesaurus';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends Element {
  //title = Thesaurus.project
  slug = 'app'

  //@ts-ignore
  readonly nonIE = document.documentMode === undefined
}
