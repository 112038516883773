import {Input, OnInit, Directive, Component, AfterViewInit} from '@angular/core';
import * as $ from 'jquery';
import {Thesaurus} from '../thesauri/thesaurus';
import {Slugify} from './Slugify';
import {DataService} from './data.service';
import {LanguageService} from './language.service';

/**
 * PM (04.07.2019)
 */
//@Directive()
export abstract class Element implements OnInit, AfterViewInit, Slugify {
  readonly images = '/assets/images/'
  readonly imagesOthers = this.images +'others/'

  readonly docs = '/assets/documents/' //ML (16.07.2019)
  readonly videos = '/assets/videos/' //PM (13.12.2019)
  readonly active = 'active' //flag mostly used as a CSS attribute (such as a class) to set a state
  readonly disabled = 'disabled' //...
  readonly root = './'
  readonly umbrella = 'sigma'
  readonly prefix = this.umbrella +'-' //PM (17.07.2019)
  protected readonly dom = $(document) //PM (17.07.2019)
  slug: string
  images2: string


  /** AFTER `constructor` & BEFORE the DOM is loaded */
  ngOnInit() { this.images2 = this.images + this.slug +'/'; }

  /** AFTER the DOM is completely loaded */
  ngAfterViewInit(): void {}

  /**
   * this function is used to get DOM (EventTarget) object
   * @param event the DOM event
   * @return Elt extends HTMLElement
   */
  eltEv<Elt extends HTMLElement = HTMLElement>(event: Event): Elt { return (event.target || event.currentTarget) as Elt }

  /**
   * this function is used to get jQuery DOM wrapper object
   * @param event the DOM event
   */
  eltEvJ(event: Event) { return $(this.eltEv(event)) }

  /**
   * this function is used to get DOM object
   * @param id
   * @return Elt extends HTMLElement
   */
  eltId<Elt extends HTMLElement = HTMLElement>(id: string): Elt {
    return document.getElementById(id) as Elt
  }

  /**
   * this function is used to get DOM object
   * @param query
   * @return Elt extends HTMLElement
   */
  eltOr<Elt extends HTMLElement = HTMLElement>(query: string): Elt {
    return document.querySelector(query) as Elt
  }

  /**
   * this function is used to get DOM object
   * @param query
   * @return Elt extends HTMLElement
   */
  eltOrs<Elt extends HTMLElement = HTMLElement>(query: string): NodeListOf<Elt> {
    return document.querySelectorAll(query) as NodeListOf<Elt>
  }

  /**
   * this function is used to get jQuery DOM wrapper object
   * @param target
   */
  eltJ(target: string|Element|HTMLElement) { return $(target) }

  /**
   *
   * @param key
   */
  theo(key: string): string { return LanguageService.text(this.underscore(key)) }

  /**
   * this function is used to access the translated data
   * @param key {string}
   * @return {string} the translated data corresponding to the key
   */
  theoDyn<DataType = any>(key: string): DataType { return LanguageService.theo[key] as DataType }

  /**
   * @since PM (07.07.2020) use `this.leo` for simple access instead;
   *                        this function is used to access complex thesaurus properties
   *                        workaround of the design flaw stated below (#1)
   */
  get language() { return LanguageService } //used in HTML

  get thesaurus() { return Thesaurus; } //used in HTML
  get dataService() { return DataService; } //used in HTML

  /**
   * this function is used to generate a simple placeholder for the given key
   * @param key
   * @return placeholder
   */
  lipsum(key: string): string { return LanguageService.lipsum(key) }

  /**
   * this function is used to normalise to the kebab-case
   * @param term
   * @return term in kebab-case format
   */
  kebabise(term: string): string {
    return term
      .replace(/[A-Z]+/g, chars => `-${chars.toLowerCase()}`)
      .replace(/[\W_]+/g, '-')
  }

  /**
   *
   * @param term
   */
  underscore(term: string): string {
    return term
      .replace(/[A-Z]+/g, chars => `-${chars.toLowerCase()}`)
      .replace(/[\W-]+/g, '_')
  }
}

/**
 * PM (04.07.2019)
 */
export abstract class Block extends Element {
  readonly classNameDef = 'col-md-6'
  readonly imagesDiscTrans = this.images +'disciplines-transparent/'
  readonly imagesDiscColor = this.images +'disciplines-color/'
  readonly imagesSections = this.images +'sections/'
  readonly imagesIntro = this.images +'intro/'
  readonly imagesZYWX = this.images +'zyxw/'
  readonly millis = 1000


  /**
   * #1
   * design that @Input()s are not inherited
   * @see https://stackoverflow.com/a/35548148
   * @see https://github.com/angular/angular/issues/5794
   */
  //@Input() className = 'col-md-6' //the default value

  //ngOnInit(){}

  /**
   * this function is used to replace the placeholder by the adequate replacer
   * @param key
   * @param rep
   * @return string
   */
  keyRep(key: string, rep: string): string {
    return key.replace(/^#/, rep)
  }

  /**
   * this function is used to remove the placeholder
   * @param key
   * @param reps
   * @return string
   */
  keyRem(key: string, ...reps: string[]): string {
    return key
      .replace(/^#_/, '')
      .replace(new RegExp(`^(${reps.join('|')})_`), '')
  }
}

/**
 * PM (04.07.2019)
 */
export abstract class Section extends Block {}

/**
 * PM (20.07.2020)
 */
export abstract class Row extends Block {}

/**
 * PM (04.07.2019)
 */
export abstract class Column extends Block {
  public ready = false //true if the component is ready TO render the actual chart
}

