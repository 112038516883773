/**
 * this script is used to create the chart bar logic
 * @since PM (18.03.2020)
 * @see https://apexcharts.com
 * @see https://apexcharts.com/docs/angular-charts/
 * @see https://apexcharts.com/angular-chart-demos/area-charts/basic/
 * @see https://apexcharts.com/javascript-chart-demos
 * @see https://apexcharts.com/docs/creating-first-javascript-chart/
 */
import {Chart as ChartData, Data, Statistic} from '../../utils/interfaces'
import {Values} from '../../utils/values'
import {Units} from '../../utils/units'
import {ChartForm} from '../../utils/Page'
import Diagram from '../../utils/Diagram'

export default abstract class Chart extends Diagram {
  private _type = ''
  private _identifier = ''
  private _keys: string[] = [] //stats keys
  private _statistics: Data<Statistic> = {}
  private _col = 'col col-sm col-lg'
  protected _data: ChartData|null
  protected _colors: string[]|string[][] = null
  protected _options = null

  readonly slug = 'chart'
  readonly optionsDef = {
    //https://apexcharts.com/docs/options/stroke/
    stroke: {
      width: 2,
      curve: 'smooth',
      colors: [] //colors top
    },
    dataLabels: {
      enabled: false,
      //style: {colors: ['#F44336', '#E91E63', '#9C27B0']}
    },

    /**
     * @see https://apexcharts.com/docs/gradients/
     * @see https://codepen.io/apexcharts/pen/GQmoXP
     * @see https://apexcharts.com/angular-chart-demos/radialbar-charts/gradient/
     * @see https://apexcharts.com/docs/options/xaxis/#crosshairsFillGradient
     * @see https://apexcharts.com/docs/options/colors/
     *
     * @todo const agent = window.navigator.userAgent.toLowerCase() -> safari
     * @see https://stackoverflow.com/questions/48182912/how-to-detect-browser-with-angular
     */
    fill: {
      type: 'gradient',
      colors: [], //colors top
      /*
      color: '#B1B9C4',
      colors: ['#1A73E8', '#B32824'],
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.8,
        opacityTo: 1,
        stops: [0, 95, 100],
        colorStops: ['#F44336', '#E91E63', '#9C27B0']
      }
      */
      gradient: {
        //shade: 'dark',
        //inverseColors: true,
        type: 'vertical', //from top to bottom
        shadeIntensity: 0.5,
        gradientToColors: ['#fff'], //colors bottom
        opacityFrom: 1, //top
        opacityTo: 1, //bottom
        stops: [0, 100]
      }
    },

    //https://apexcharts.com/docs/options/nodata/
    noData: {
      text: this.theo('charts_none'),
      style: {
        //color: this.sigmaRed,
        fontSize: '1em',
        fontFamily: 'Roboto'
      }
    }
  }
  public stat: Statistic|null


  /**
   * this function is used to determine the chart form
   * @return {ChartForm} the chart type
   */
  abstract form(): ChartForm


  get type(): string { return this._type }
  get data(): ChartData|null { return this._data }
  get identifier(): string { return this._identifier }
  get options(): any { return this._options }
  get keys(): string[] { return this._keys }
  get col(): string { return this._col }
  get statistics(): Data<Statistic> { return this._statistics }

  /**
   * this function is used to get thesaurus value
   * @param key
   * @param type
   * @return string
   */
  theo2(key: string, type: string): string {
    return this.theo(this.keyRem(key, type, this._type))
  }


  /**
   * this function is used to set the chart various values
   * @final
   * @param {ChartData} data
   */
  protected set(data: ChartData){
    this._identifier = this.kebabise(this._type = data.type)
    //statistics
    let stat: Statistic|null = null
    data.keys.forEach(key => {
      stat = data.statistics[this.keyRep(key, this._type)]
      if(stat && stat.value !== null){
        this._keys.push(key = this.keyRep(key, data.type))
        this._statistics[key] = stat
      }
    })
    if(this._keys.length > 3) this._col = 'col-6 col-sm-4 col-md-6 col-lg-6 col-xl-4' //maximum 3 values in a row

    /**
     * actual charts
     * asynchronous setting to prevent the chart renderer from block the DOM
     */
    this._data = data //should come last
    this._colors = data.colors
    const interval = setInterval(() => {
      if(this.ready){
        clearInterval(interval)
        this.setOptions(this)
      }
    }, this.millis)
  }

  /**
   * this function is used to set the `_options` property;
   * it can be overridden if necessary
   * @param chart = this
   */
  protected setOptions(chart: Chart){
    const
      points = this._data.points,
      typeX = points.typeX || Units.dist, //the type used to handle the x-axis
      grid = this._data.grid,
      style = {style: {fontFamily: 'Roboto, sans-serif'}},
      tickAmount = points.length > 5 ? 4 : (points.length > 3 ? 3 : 'dataPoints')
    let color = this.sigmaRed
    if(this._colors){
      const temp = this._colors[this._colors.length - 1]
      color = typeof temp === 'string' ? temp : temp[temp.length - 1]
    }

    this._options = {
      chart: {
        type: this.form(),
        toolbar: { //@see https://apexcharts.com/docs/options/chart/toolbar/
          tools: {
            download: false,
            pan: false,
            zoom: true, //must be true to enable the selected zoom function
            zoomin: `<i class="icon-search-plus button-tool-custom"></i>`,
            zoomout: false,
            reset: `<i class="icon-search-minus button-tool-custom"></i>`,
          }
        },

        /**
         * @see https://apexcharts.com/docs/localization/
         * @see https://mariusschulz.com/articles/importing-json-modules-in-typescript
         */
        locales: [this.language.theo.chart],
        defaultLocale: this.language.lang
      },
      grid: { /** @see https://apexcharts.com/docs/options/grid/ */
        show: grid,
        padding: {
          right: typeX === Units.dist ? points.labels[points.length - 1].toString().length * 10/*10px*/ / 2 : 0, //padding-right because of the unite 'km'
        }
      },
      series: points.lists.map(list => {
        return {
          name: this.theo(this._type),
          data: grid ? list.map(value => Values.parse(points.typeY || this._type, value)) : []
          //data: noData ? [] : list.map(value => value === null ? value : Values.parse(points.typeY || this._type, value))
        }
      }),
      title: {
        ...style,
        text: Units.get(this._type)
      },
      xaxis: {
        type: 'numeric',
        categories: grid ? points.labels : [],
        /**
         * #alternative
         * @see https://apexcharts.com/docs/formatting-axes-labels/
         * @see https://apexcharts.com/docs/options/xaxis/#formatter
         */
        tickAmount,
        labels: {
          ...style,
          formatter : typeX === Units.dist ?
            (value, _, index) => {
              return index === tickAmount || index === points.length - 1 ?
                `${Values.parse(typeX, value, 0)} ${Units.get(typeX)}` :
                Values.parse(typeX, value, 0)
            } :
            value => Values.parse(typeX, value)
        },
        tooltip: {
          enabled: false, /** @since PM (26.08.2020) the tooltip on the x-axis is now off */
          formatter(value): string {
            return `${Values.parse(typeX, value)} ${Units.get(typeX)}`
          }
        }
      },

      /**
       * @see https://apexcharts.com/docs/grid/
       * @see https://apexcharts.com/docs/options/yaxis/
       */
      yaxis: points.typeY ?
        {
          opposite: false,
          labels: {
            ...style,
            formatter(value): string {
              return Values.parse(points.typeY + Units.label, value)
            }
          }
        } :
        {
          opposite: false,
          labels: {...style}
        },
      tooltip: { //@see https://apexcharts.com/docs/options/tooltip/
        marker: this.noShow, //the marker had to be hidden because of the colors
        x: typeX === Units.dist ?
          {
            /**
             * #1
             * `this.theo` won't work here because of use the use of the anonymous callback function;
             * hence the work around: `chart.theo`
             * @param value
             */
            formatter(value): string {
              return `${chart.theo(Units.dist)} ${Values.parseD(value)} ${Units.distance}`
            }
          } : {
            formatter(value): string {
              //return Values.parse(typeX, value)
              return `${Values.parse(typeX, value)} ${Units.get(typeX)}`
            }
          }
        ,
        y: {
          //`this.type` won't work; for more info see #1
          formatter(value): string {
            return Values.parse((points.typeY || this._type) + Units.label, value) +' '+ Units.get(chart.type)
          }
        }
      },
      annotations: points.maximum ? { //@see https://apexcharts.com/docs/options/annotations/
        yaxis: [
          {
            y: Values.parse(points.typeY || this._type, points.maximum),
            borderColor: color,
            label: {
              borderColor: color,
              style: {
                color: '#000',
                background: color,
              },
              text: this.theo('max') +' '+ Values.parse((points.typeY || this._type) + Units.label, points.maximum),
            }
          }
        ]
      } : { position: 'front' }
    }
  }

  /**
   * this function is used to set the default colors
   * @final
   */
  protected setColors(){
    if(this._colors){
      this.optionsDef.stroke.colors = this._colors
      this.optionsDef.fill.colors = this._colors
      //this.optionsDef.fill.gradient.gradientToColors = this._colors
      this._options.markers = { //@see https://apexcharts.com/docs/options/markers/
        colors: [this.sigmaRed],
        //colors: this._colors,
        strokeColors: this.sigmaRed,
        strokeWidth: 1,
      }
    }
  }
}
