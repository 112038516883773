import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {DataPrivacyComponent} from './pages/data-privacy/data-privacy.component';
import {ImprintComponent} from './pages/imprint/imprint.component';
import {ResourceComponent} from './pages/resource/resource.component';
import {ResourceActivityComponent} from './pages/resource-activity/resource.activity.component';
import {ResourceTrackerComponent} from './pages/resource-tracker/resource.tracker.component';
import {ResourceRouteComponent} from './pages/resource-route/resource.route.component';
import {ThemeComponent} from './pages/theme/theme.component';

/**
 * PM (19.06.2019)
 * activity -> activity
 * tracker -> activity tracker
 * route -> track
 * @see https://angular.io/guide/router
 *      https://medium.com/@christo8989/angular-6-url-parameters-860db789db85
 *      https://medium.com/developing-an-angular-4-web-app/adding-routes-for-login-and-home-view-components-be676d786471
 *      https://www.sitepoint.com/component-routing-angular-router/ # children
 */
const routes: Routes = (() => {
  const data = {data: {debug: true}}

  return [
    {path: '', component: HomeComponent, pathMatch: 'full'},
    {path: 'data-privacy', component: DataPrivacyComponent},
    {path: 'privacy-policy', component: DataPrivacyComponent},
    {path: 'imprint', component: ImprintComponent},
    {path: 'theme', component: ThemeComponent},
    {
      path: 'view',
      component: ResourceComponent,
      children: [
        {path: 'activity/:identifier', component: ResourceActivityComponent},
        {path: 'tracker/:identifier', component: ResourceTrackerComponent},
        {path: 'route/:identifier', component: ResourceRouteComponent},
      ]
    },
    {
      path: 'OXWCzhbiOtYA8Q9RsdHKw97kocHGfy7XO8BqWDkOQOzMlEcd2X', //debug mode
      component: ResourceComponent,
      children: [
        {
          path: 'view',
          component: ResourceComponent,
          children: [
            {path: 'activity/:identifier', component: ResourceActivityComponent, ...data},
            {path: 'tracker/:identifier', component: ResourceTrackerComponent, ...data},
            {path: 'route/:identifier', component: ResourceRouteComponent, ...data},
          ]
        },
      ]
    },
    {path: '**', redirectTo: ''}
  ];
})()

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  //imports: [ RouterModule.forRoot(routes, {enableTracing: true}) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
