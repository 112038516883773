import { Pipe, PipeTransform } from '@angular/core';

/**
 * @see https://angular.io/guide/pipes
 * @since PM (07.07.2020)
 */
export enum Unit {
  //distance
  mm = 'mm',
  m = 'm',
  km = 'km',
  ft = 'ft', //foot
  mi = 'mi',

  //degree
  degree = '°', //&deg;
  c = '°C', //celsius: &deg;C
  f = '°F', //fahrenheit: &deg;F

  //speed
  ms = 'm/s', //meter per seconds
  kmh = 'km/h', //kilometer per hour
  mph = 'mph', //miles per hour

  //others
  bpm = 'bpm', //heart rare
  lb = 'lb', //pound
  kcal = 'kcal',
  kg = 'kg',
  h = 'h',
  pct = '%',
  rpm = 'rpm', //
  watt = 'watt',

  //...
  none = '--',
}

/**
 *
 */
export class Units {
  //the default values
  private static _altitude: Altitude = Unit.m
  private static _distance: Distance = Unit.km
  private static _speed: Speed = Unit.kmh
  private static _temperature: Temperature = Unit.c
  private static _weight: Weight = Unit.kg
  static readonly dist = 'distance'
  static readonly label = '_label'


  static set(){
    const
      altitude = localStorage.getItem('unit_altitude') as Altitude|null,
      distance = localStorage.getItem('unit_distance') as Distance|null,
      temperature = localStorage.getItem('unit_temperature') as Temperature|null,
      weight = localStorage.getItem('unit_weight') as Weight|null

    if(altitude === Unit.ft) this._altitude = Unit.ft
    if(distance === Unit.mi){
      this._distance = Unit.mi
      this._speed = Unit.mph
    }
    if(temperature === Unit.f) this._temperature = Unit.f
    if(weight === Unit.lb) this._weight = Unit.lb
  }

  static get(type: string): string {
    switch(type) {
      case 'altitude': return this._altitude
      case 'calories': return Unit.kcal
      case 'cadence': return Unit.rpm
      case 'heart_rate': return Unit.bpm
      case 'number': return ''
      case 'power': return Unit.watt
      case 'speed': return this._speed
      case 'temperature': return this._temperature
      case 'weight': return this._weight
      //...
      case 'assist':
      case this.dist:
      case this.dist + this.label:
        return this._distance
      case 'battery':
      case 'percent':
        return Unit.pct
      case 'time':
      case 'time_h':
      case 'time_hm':
      case 'time_x': //used by charts having the time as x-axis unit
      case 'time_y': //used by charts having the time as y-axis unit
      case 'times':
        return Unit.h
    }
    return ''
  }

  static set altitude(altitude: Altitude){
    this._altitude = altitude
    localStorage.setItem('unit_altitude', altitude)
  }
  static get altitude(): Altitude { return this._altitude }

  static set distance(distance: Distance){
    this._distance = distance
    this._speed = distance === Unit.mi ? Unit.mph : Unit.kmh
    localStorage.setItem('unit_'+ this.dist, distance)
  }
  static get distance(): Distance { return this._distance }
  static get speed(): Speed { return this._speed }

  static set temperature(temperature: Temperature){
    this._temperature = temperature
    localStorage.setItem('unit_temperature', temperature)
  }
  static get temperature(): Temperature { return this._temperature }

  static set weight(weight: Weight){
    this._weight = weight
    localStorage.setItem('unit_weight', weight)
  }
  static get weight(): Weight { return this._weight }
}

type Altitude = Unit.mm|Unit.m|Unit.ft
type Distance = Unit.m|Unit.km|Unit.mi
type Speed = Unit.ms|Unit.kmh|Unit.mph
type Temperature = Unit.c|Unit.f
type Weight = Unit.kg|Unit.lb


/**
 *
 */
@Pipe({ name: 'getUnit' })
export class UnitPipe implements PipeTransform {
  transform(type: string, ...args: any[]): string { return Units.get(type) }
}
