import {Column} from './elements';

export default class Diagram extends Column {
  /**
   * @see https://apexcharts.com/docs/options/legend/
   */
  readonly noShow = { show: false }
  readonly noShadow = { enabled: true }
  readonly sigmaRed = '#c71232'


  /**
   * this function is used to generate/render the chart & add its snippet to the DOM
   */
  ngAfterViewInit() {
    super.ngAfterViewInit()
    this.ready = true
  }
}
