<!--
  this script is used to create the download HTML snippet
  @since PM (xx.xx.2020)
-->
<section id="{{slug}}" class="modal fade" tabindex="-1" role="dialog" [attr.aria-hidden]="true">
  <div class="modal-{{slug}} modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="text-right modal-close">
        <button type="button" class="button-icon close" [attr.data-dismiss]="'modal'" [attr.aria-label]="theo('close')">
          <i class="icon-close"></i>
        </button>
      </div>
      <div class="modal-body">
        <div class="title mb-2">
          <h2 class="font-color">{{theo('download')}}</h2>
          <span class="instruction" [innerHTML]="theo('select_files')"></span>
        </div>
        <div class="row">
          <div *ngFor="let key of keys" class="col-12 col-md-6 selection row">
            <div class="col-2 col-md-3 pr-2 value clickable">
              <i *ngIf="list[key]; else elseIcon" class="icon-toggle-on" (click)="update(key)"></i>
              <ng-template #elseIcon>
                <i class="icon-toggle-off" (click)="update(key)"></i>
              </ng-template>
            </div>
            <div class="col pl-2 label">{{theo(key)}}</div>
          </div>
        </div>
        <div class="row mt-3 mb-3">
          <div class="col-12 col-md-5">
            <button type="button" class="btn button-sigma" (click)="download($event)" [disabled]="inactive">{{theo('download_start')}}</button>
          </div>
          <div class="col">
            <div *ngIf="downloading" class="loader"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="downloading" class="modal-{{slug}}-mask full"></div>
</section>
