import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Block} from '../../utils/elements';
import {Data, Round, Strimber} from '../../utils/interfaces';
import {Units} from '../../utils/units';
import {Values} from '../../utils/values';

/**
 * this script is used to create the chart line logic
 * @since PM (xx.xx.2020)
 */
@Component({
  selector: 'table-laps',
  templateUrl: './laps.component.html',
  styleUrls: ['./laps.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class LapsComponent extends Block {
  @Input() set config(config: Data<string[]>){
    this._config = config

    this._labels = []
    let unit = ''
    Object.keys(config).forEach((type: string) => { //labels
      config[type].forEach((key: string) => {
        unit = Units.get(type)
        this._labels.push(this.theo(`${type}_${key}`) + (unit ? ` [${unit}]` : ''))
      })
    })
  }
  @Input() identifier: string
  @Input() set rounds(rounds: Round[]){
    this._lines = []
    let line: Line[]
    let value: Strimber|null
    let label = '', unit = ''
    rounds.forEach((round: Round) => {
      line = []
      Object.keys(this._config).forEach((type: string, i: number) => {
        this._config[type].forEach((key: string, j: number) => {
          value = round[type][key] ?
            Values.parse(type, round[type][key]) :
            this.lipsum(`${type}-${key}`)
          if(i+j === 0 && type === 'number') label = this.theo('lap') +' '+ value
          else{
            unit = Units.get(type)
            label = this.theo(`${type}_${key}`) + (unit ? ` [${unit}]` : '')
          }

          line.push({identifier: `${type} ${type}-${key}`, label, value})
        })
      })
      this._lines.push(line)
    })
  }


  private _config: Data<string[]> = {}
  private _labels: string[] = []
  private _lines: Line[][] = []

  readonly slug = 'laps'

  get labels(): string[] { return this._labels }
  get lines(): Line[][] { return this._lines }
}

type Line = {identifier: string, label: string, value: Strimber}
