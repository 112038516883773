import {Component, ViewEncapsulation} from '@angular/core';
import Resource from '../resource/resource';
import {Intro, Points} from '../../utils/interfaces';
import {ChartForm} from '../../utils/Page';

@Component({
  selector: 'app-root',
  templateUrl: './resource.route.component.html',
  styleUrls: ['./resource.route.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ResourceRouteComponent extends Resource {

  protected custom(data: any, config: any){}
}
