import {Component, ViewEncapsulation} from '@angular/core';
import {Page} from '../../utils/Page';

/**
 * this class is used to implement the imprint page
 */
@Component({
  selector: 'app-root',
  templateUrl: './imprint.component.html',
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ImprintComponent extends Page {
  slug = 'imprint'
}
