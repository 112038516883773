import {Injectable, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Thesaurus} from '../thesauri/thesaurus';
import {ThesaurusDE} from '../thesauri/thesaurusDE';
import {ThesaurusEN} from '../thesauri/thesaurusEN';
import {ThesaurusNL} from '../thesauri/thesaurusNL';
import {Title} from '@angular/platform-browser';
import {Slugify} from './Slugify';

@Injectable({
    providedIn: 'root',
})
export class LanguageService implements Slugify /*, OnInit*/ {
  static readonly thesauri = {
    de: new ThesaurusDE('de', 'Deutsch'), //PM (24.07.2019)
    en: new ThesaurusEN('en', 'English'), //PM (xx.07.2019)

    /*
    cs: new ThesaurusEN, //TODO
    de: DONE
    en: DONE
    es: new ThesaurusEN, //TODO
    fr: new ThesaurusEN, //TODO
    it: new ThesaurusEN, //TODO
    pl: new ThesaurusEN, //TODO
    nl: new ThesaurusNL, //TODO
    */
    /** @todo add the other thesauri here */
  };
  static readonly codes = Object.keys(LanguageService.thesauri);
  private static readonly default = 'en';
  private static _theo: Thesaurus =
    LanguageService.thesauri[LanguageService.default] ||
    LanguageService.thesauri[LanguageService.codes[0]]
  ;
  private static _lang: string;
  static slug = 'language'
  readonly slug = LanguageService.slug;


  /**
   * this function is used to initialise the language & thesaurus
   * @see https://stackoverflow.com/questions/36914151/browser-language-detection?noredirect=1&lq=1 # navigator.language
   */
  //constructor(private cookieService: CookieService, private titleService: Title){
  constructor(private titleService: Title){
    //DO NOT set the language here, it is too early; the page therefore takes care of it on init
  }


  /* alternative
  ngOnInit(): void {
      //this.set(this.cookieService.get(this.slug) || navigator.language || this.default);
  }
  */

  static get theo(): Thesaurus { return this._theo }
  static get lang(): string { return this._lang }

  /**
   * this function is used to access the translated texts;
   * it should be used if the data corresponding to the given key is of type `string`
   * @param key {string}
   * @return {string} the translated & parsed text corresponding to the key
   */
  static text(key: string): string {
    const text = this._theo[key]
    return text ? text[0].toUpperCase() + text.replace(/^./, '') : this.lipsum(key)
  }

  /**
   * this function is used to generate a simple placeholder for the given key
   * @param key
   * @return placeholder
   */
  static lipsum(key: string): string { return Thesaurus._ + key + Thesaurus._ }

  /**
   * this function is used to set/update the language & thesaurus
   * @param lang the key of the new language
   * @return Thesaurus the currently active
   */
  set(lang: string = LanguageService.default): void {
    //console.log(navigator.language.substr(0, 2))
    if(LanguageService._lang){
      //DO NOT MERGE
      if(lang in LanguageService.thesauri){
        this.setIn(lang);
        //location.reload();
      }
    }else{ //on first invocation during the loading process

      //lang = this.cookieService.get(this.slug) || navigator.language.substr(0, 2);
      lang = localStorage.getItem(LanguageService.slug) || navigator.language.substr(0, 2);
      if(!(lang in LanguageService.thesauri)) lang = LanguageService.default;

      this.setIn(lang)
    }
  }


  private setIn(lang: string){
    //this.cookieService.set(this.slug, lang); //buffer the selection
    localStorage.setItem(LanguageService.slug, lang); //buffer the selection
    LanguageService._theo = LanguageService.thesauri[lang];
    LanguageService._lang = lang

    this.titleService.setTitle(Thesaurus.project)
  }
}
