import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Section} from '../../utils/elements';
import {LanguageService} from '../../utils/language.service';
import {Unit, Units} from '../../utils/units';

/**
 * this script is used to create the !!! logic
 * @since PM (xx.xx.2020)
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 * https://apexcharts.com/docs/options/title/
 */
@Component({
  selector: 'modal-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class SettingsComponent extends Section {
  static slug = 'settings' //DO NOT REMOVE
  readonly slug = SettingsComponent.slug
  private _settings: Settings[] = []
  private updated = false //TRUE if an update has occurred

  @Input() lang: LanguageService
  @Input() full = true //TRUE if the complete version of the settings should be presented


  ngOnInit(){
    super.ngOnInit()

    this._settings = [
      {
        identifier: LanguageService.slug,
        value: LanguageService.lang,
        name: 'select_language',
        list: LanguageService.codes,
      }
    ]
    if(this.full) this._settings.push(
      {
        identifier: 'altitude',
        value: Units.altitude === Unit.m,
        pair: {left: 'meter', right: 'foot'}
      },
      {
        identifier: Units.dist,
        value: Units.distance === Unit.km,
        pair: {left: 'kilometer', right: 'miles'}
      },
      {
        identifier: 'temperature',
        value: Units.temperature === Unit.c,
        pair: {left: 'celsius', right: 'fahrenheit'}
      },
      {
        identifier: 'weight',
        value: Units.weight === Unit.kg,
        pair: {left: 'kilogram', right: 'pound'}
      }
    )
  }

  get settings(): Settings[] { return this._settings }

  /**
   * this function is used to handle the update event
   * @param identifier
   * @param value
   * @param i
   */
  update(identifier: string, value: string, i: number){
    switch(identifier){
      case LanguageService.slug:
        this.lang.set(value)
        this._settings[i].value = value //visualisation of the update
        break
      case 'altitude':
        Units.altitude = value === 'foot' ? Unit.ft : Unit.m
        this._settings[i].value = !this._settings[i].value //visualisation of the update
        break
      case Units.dist:
        Units.distance = value === 'miles' ? Unit.mi : Unit.km
        this._settings[i].value = !this._settings[i].value //visualisation of the update
        break
      case 'temperature':
        Units.temperature = value === 'fahrenheit' ? Unit.f : Unit.c
        this._settings[i].value = !this._settings[i].value //visualisation of the update
        break
      case 'weight':
        Units.weight = value === 'pound' ? Unit.lb : Unit.kg
        this._settings[i].value = !this._settings[i].value //visualisation of the update
        break
      default: return; //DO NOT REMOVE
    }
    //DO NOT SWITCH
    this.updated = true
  }

  /**
   * this function is used to handle the close event in addition to the native bootstrap behavior
   */
  close(event: Event){
    //event.stopPropagation()

    let elt: Element = this.eltEv(event)
    if(elt.matches('#'+ this.slug)) elt = elt.firstElementChild
    else if(elt.matches('button.close') || elt.matches('i.icon-close')) elt = this.eltId(this.slug)?.firstElementChild
    else elt = null
    //...
    elt?.classList.remove(this.active) //remove the active class
    if(elt && this.updated){ //reload the page only if an update has occurred
      this.updated = false
      //@see https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
      window.dispatchEvent(new CustomEvent(
        this.slug +'Update',
        { detail: {timestamp: new Date()} }
      ))
    }
  }
}

type Settings = {
  identifier: string,
  value: string|boolean,
  name?: string,
  list?: string[],
  pair?: {left: string, right: string}
}
