<!--
  this script is used to create the header HTML snippet
  @since ML (xx.06.2020)
  @see https://getbootstrap.com/docs/4.0/components/modal/#via-data-attributes
-->
<section id="{{slug}}" class="container">
  <nav class="row">
    <div class="col-3">
      <a class="logo" href="{{dataService.companyURL2}}">
        <img src="{{imagesOthers}}logo.svg" class="ml-2 mt-1" alt="{{dataService.company}}">
      </a>
    </div>
    <div class="col-4 col-md-6 text-center text-uppercase">
      <h1 [innerHTML]="theo('title')"></h1>
    </div>
    <div class="col-5 col-md-3 text-right">
      <button class="btn button-icon" *ngIf="download" type="button" [attr.data-toggle]="'modal'" [attr.data-target]="targets.download">
        <i class="icon-download"></i>
      </button>
      <button class="btn button-icon" type="button" [attr.data-toggle]="'modal'" [attr.data-target]="targets.settings" (click)="settingsHandler()" >
        <i class="icon-bars"></i>
      </button>
    </div>
  </nav>
</section>
