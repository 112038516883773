import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

//hammer.js in angular
//@see https://levelup.gitconnected.com/making-hammerjs-work-with-angular-9-81d289159320
//@see https://medium.com/madewithply/ionic-4-long-press-gestures-96cf1e44098b
//@see https://medium.com/angular-in-depth/gestures-in-an-angular-application-dde71804c0d0
import 'hammerjs';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
