<ng-template [ngIf]="timestamp">
  <aside class="modals">
    <modal-settings [lang]="lang"></modal-settings>
    <modal-download [identifier]="identifier" [data]="download"></modal-download>
    <alert-cookies-admin></alert-cookies-admin>
  </aside>
  <main>
    <article class="resource resource-{{resource}}">
      <section-header [data]="download"></section-header>
      <ng-template [ngIf]="done && !error">
        <section class="container">
          <row-headline [target]="sectionIdentifier.overview" [head]="head" [scroller]="scroller"></row-headline>
          <div id="{{sectionIdentifier.overview}}" class="{{row}}">
            <div class="col-md">
              <column-intro [intro]="intro"></column-intro>
            </div>
            <div class="col-md">
              <column-map [coordinates]="coordinates"></column-map>
            </div>
          </div>
        </section>
        <section class="container">
          <row-title
            *ngIf="dataService.devModePM"
            [identifier]="sectionIdentifier.charts"
            [namespace]="identifier"
            [scroller]="scroller"
          ></row-title>
          <div id="{{sectionIdentifier.charts}}" class="{{row}}">
            <div *ngFor="let chart of charts" class="col-md-{{col}}">
              <column-chart-area *ngIf="!chart.form || chart.form == chartForm.area" [chart]="chart"></column-chart-area>
              <column-chart-bar *ngIf="chart.form == chartForm.bar" [chart]="chart"></column-chart-bar>
            </div>
          </div>
        </section>
        <section-footer></section-footer>
      </ng-template>
      <aside *ngIf="done && error" class="center blank blank-{{slug}}">
        <img src="{{imagesOthers}}logo.svg" alt="logo">
        <p [innerText]="error"></p>
      </aside>
    </article>
  </main>
</ng-template>
