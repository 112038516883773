import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Section} from '../../utils/elements';
import {Paragraph} from '../../utils/interfaces';

/**
 * @since PM (20.07.2020)
 * this component is a simple wrapper of the title.component;
 * its purpose is to simplify the theme development
 */
@Component({
  selector: 'alert-cookies-admin',
  templateUrl: './cookies-admin.component.html',
  styleUrls: ['./cookies-admin.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class CookiesAdminComponent extends Section {
  readonly slug = 'cookies-admin'
  private readonly key = this.underscore(this.slug)
  private _visible = true
  readonly paragraph: Paragraph = {
    tertiary: this.theo('title'),
    content: [this.theo('content')]
  }

  constructor() {
    super();
    this._visible = localStorage.getItem(this.key) !== 'off'
  }

  set visible(visible: boolean){
    this._visible = visible
    if(visible) localStorage.removeItem(this.key)
    else localStorage.setItem(this.key  , 'off')
  }
  get visible(): boolean { return this._visible }

  /**
   * @override
   * @param key
   */
  theo(key: string): string {
    return super.theo(this.slug +'-'+ key); //super.underscore is implicitly used
  }
}
