import {Component, Input, ViewEncapsulation} from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector'; //https://www.npmjs.com/package/ngx-device-detector
import Chart from '../chart/Chart';
import {Chart as ChartData} from '../../utils/interfaces';
import {ChartForm} from '../../utils/Page';

/**
 * this script is used to create the chart line logic
 * @since PM (xx.xx.2020)
 */
@Component({
  selector: 'column-chart-area',
  templateUrl: './chart.area.component.html',
  styleUrls: ['./chart.area.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ChartAreaComponent extends Chart {
  @Input() set chart(data: ChartData){ this.set(data) }


  constructor(private deviceService: DeviceDetectorService){ super() }


  form(): ChartForm { return ChartForm.area }

  /**
   * @override add the plotOptions
   * @param chart
   */
  setOptions(chart: Chart) {
    super.setOptions(chart)

    const info = this.deviceService.getDeviceInfo() /** @since PM (08.10.2020) bug fix on safari < version 14 */
    if(info.browser.toLowerCase() === 'safari' && info.browser_version < '14') this.optionsDef.fill.type = 'solid'

    this.setColors()
    this._options.xaxis.crosshairs = {
      show: true,
        width: 2,
        position: 'front',
        stroke: {
          color: this.sigmaRed,
          width: 1,
          dashArray: 0
      },
      fill: {
        type: 'solid',
        color: this.sigmaRed,
      },
      dropShadow: this.noShadow
    }
  }
}
