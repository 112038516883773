/**
 * @since PM (04.07.2019)
 */
import {Element} from './elements';
import {LanguageService} from './language.service';
import {ChangeDetectorRef, OnInit} from '@angular/core';
import {Head} from './interfaces';


//@Directive()
export class Page extends Element implements OnInit {
  private static langSet = false
  private _scroller: any;
  private _timestamp = new Date()

  /**
   * this property is used as default class of the collapsible rows
   * @see https://getbootstrap.com/docs/4.5/components/collapse/#accordion-example
   * @see https://stackoverflow.com/a/56406141/2652918
   */
  readonly row = 'row collapse show'


  constructor(public lang: LanguageService, protected changeDetector: ChangeDetectorRef) { super() }

  ngOnInit(){
    super.ngOnInit()
    this._scroller = this.eltJ('html,body'); //html for safari, body for the rest of the major browsers
    if(!Page.langSet){ //bug fix: the entire class is invoked twice during the rendering
      Page.langSet = true
      this.lang.set(); //DO NOT REMOVE THIS LINE: the language must be set before the html snippet are rendered
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit()
    window.addEventListener('settingsUpdate', (event: CustomEvent) => {
      this._timestamp = null
      this.changeDetector.detectChanges()
      this._timestamp = event.detail.timestamp
    })
  }

  get head(): Head {
    return {
      identifier: this.slug,
      title: this.theo(this.slug),
      config: {_: 'day. month_text_short year hour:minute:second'},
      namespace: this.slug
    }
  }
  get scroller(): any { return this._scroller }
  get chartForm() { return ChartForm }
  get sectionIdentifier(){ return SectionIdentifier }
  get timestamp(): Date { return this._timestamp }


  /**
   * this function is used to get the URL of the given article
   * @param identifier
   */
  protected url(identifier: string): string {
    return window.location.href.replace(new RegExp(window.location.pathname +'$'), '/view/'+ identifier);
  }
}

/**
 * @since PM (xx.07.2019) this class is used to list all section identifiers available
 */
export enum SectionIdentifier {
  overview = 'overview',
  charts = 'charts',
  lapsReg = 'laps_reg',
  lapsAuto = 'laps_auto'
}

/**
 * @since PM (xx.07.2019) this class is used to list the different forms of charts used in the application
 */
export enum ChartForm {
  area = 'area',
  bar = 'bar',
  pie = 'pie',
  donut = 'donut',
}
