<!--
  this script is used to create the paragraph HTML snippet
  @since PM (19.08.2020)
-->
<div class="{{slug}} {{margin ? 'margin' : ''}}">
  <h2 *ngIf="paragraph.primary" [innerText]="paragraph.primary" ></h2>
  <h3 *ngIf="paragraph.secondary" [innerText]="paragraph.secondary" ></h3>
  <h4 *ngIf="paragraph.tertiary" [innerText]="paragraph.tertiary" ></h4>
  <p *ngFor="let content of paragraph.content" [innerHTML]="content"></p>
</div>
