import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Section} from '../../utils/elements';
import {LanguageService} from '../../utils/language.service';

/**
 * this script is used to create the !!! logic
 * @since PM (xx.xx.2020)
 */
@Component({
  selector: 'section-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class FooterComponent extends Section {
  @Input() className = this.classNameDef
  readonly slug = 'footer'
}
