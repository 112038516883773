<!--
  this script is used to create the theme HTML snippet
  @since PM (xx.xx.2020)
  @see https://getbootstrap.com/docs/4.0/components/modal/#via-data-attributes
  @see https://getbootstrap.com/docs/4.5/layout/grid/#how-it-works
-->
<ng-template [ngIf]="timestamp && dataService.devMode">
  <aside class="modals">
    <modal-settings [lang]="lang" [full]="true"></modal-settings>
    <modal-download [identifier]="''" [data]="download"></modal-download>
    <alert-cookies-admin></alert-cookies-admin>
  </aside>
  <main>
    <article class="resource">
      <section-header [data]="download"></section-header>
      <section class="container">
        <row-headline *ngFor="let head of heads" [target]="sectionIdentifier.overview" [head]="head"></row-headline>
        <div id="{{sectionIdentifier.overview}}" class="{{row}}">
          <div class="col-md">
            <column-intro [intro]="intro"></column-intro>
          </div>
          <div class="col-md">
            <column-map></column-map>
          </div>
        </div>
      </section>
      <section class="container">
        <row-title
          *ngIf="dataService.devModePM"
          [identifier]="sectionIdentifier.charts"
          [namespace]="slug"
          [scroller]="scroller"
        ></row-title>
        <div id="{{sectionIdentifier.charts}}" class="{{row}}">
          <div *ngFor="let chart of charts" class="col-md-{{col}}">
            <column-chart-area *ngIf="!chart.form || chart.form == chartForm.area" [chart]="chart"></column-chart-area>
            <column-chart-bar *ngIf="chart.form == chartForm.bar" [chart]="chart"></column-chart-bar>
          </div>
        </div>
      </section>
      <section class="container" *ngFor="let lap of laps">
        <row-title
          [identifier]="lap.key"
          [namespace]="dataService.devModePM ? slug : ''"
          [scroller]="scroller"
        ></row-title>
        <div id="{{lap.key}}" class="{{row}}">
          <table-laps [config]="config.laps" [identifier]="lap.identifier" [rounds]="lap.rounds"></table-laps>
        </div>
      </section>
      <section-footer></section-footer>
    </article>
  </main>
</ng-template>
<ng-template [ngIf]="!dataService.devMode">
  <aside class="center blank blank-{{slug}}">
    <img src="{{imagesOthers}}logo.svg" alt="logo">
  </aside>
</ng-template>
