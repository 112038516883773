import {Component, ViewEncapsulation} from '@angular/core';
import Resource from '../resource/resource';
import {Intro, Points, Chart, Round, Strimber} from '../../utils/interfaces';
import {SectionIdentifier, ChartForm} from '../../utils/Page';

/**
 * PM (23.06.2020)
 * @see https://meppe.sigma-sharing.de.srv05.24743.serviceprovider.de/view/activity/9e357d8c624dce4ceeb851d04d4371186d886e5c map-empty
 * @see https://meppe.sigma-sharing.de.srv05.24743.serviceprovider.de/view/activity/547fa9b6645a0d3ff2c529a4247f1a3809342064
 */
@Component({
  selector: 'app-root',
  templateUrl: './resource.activity.component.html',
  styleUrls: ['./resource.activity.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class ResourceActivityComponent extends Resource {
  private _laps: {key: string, identifier: string, rounds: Round[]}[] = []

  get laps(): {key: string, identifier: string, rounds: Round[]}[] { return this._laps }


  protected custom(data: any, config: any){
    //todo add laps coordinates to coordinates

    //laps
    this._laps = [SectionIdentifier.lapsReg, SectionIdentifier.lapsAuto]
      .map(key => { return {
        key,
        identifier: this.kebabise(key),
        rounds: data[key].rounds}
      })
      .filter(lap => lap.rounds.length)
  }
}
