import {DataService} from '../utils/data.service';
import {Slugify} from '../utils/Slugify';
import {Paragraph, Strimber} from '../utils/interfaces';
import {Unit} from '../utils/units';

export class Thesaurus implements Slugify {
  static readonly _ = '???';
  static readonly project = DataService.company +' // Sharing';


  /**
   * @constructor
   * @param slug
   * @param label
   */
  constructor(slug: string, public label: string) { this.slug = slug }
  readonly title = DataService.company +' sharing'
  readonly digits = {decimal: '.', thousand: ','}
  slug: string;

  //sport disciplines
  readonly sport: string
  readonly racing_bycicle: string
  readonly mountainbike: string
  readonly cycling: string
  readonly running: string
  readonly walking: string
  readonly hiking: string
  readonly treadmill: string
  readonly fitness: string
  readonly swimming: string
  readonly indoor_cycling: string
  readonly golf: string
  readonly inline_skates: string
  readonly ski: string
  readonly snowboarding: string
  readonly other: string
  readonly triathlon: string
  readonly ebike: string
  readonly bmx: string
  readonly cyclecross: string
  readonly enduro: string
  //...
  readonly activity: string
  readonly tracker: string
  readonly route: string

  //file formats
  readonly gpx: string = 'GPX'
  readonly kml: string = 'KML'
  readonly kmz: string = 'KMZ'
  readonly slf: string = 'SLF'
  readonly stf: string = 'STF'

  //date
  readonly date_mon: string
  readonly date_monday: string
  readonly date_tue: string
  readonly date_tuesday: string
  readonly date_wed: string
  readonly date_wednesday: string
  readonly date_thu: string
  readonly date_thursday: string
  readonly date_fri: string
  readonly date_friday: string
  readonly date_sat: string
  readonly date_saturday: string
  readonly date_sun: string
  readonly date_sunday: string
  //...
  readonly date_jan: string
  readonly date_january: string
  readonly date_feb: string
  readonly date_february: string
  readonly date_mar: string
  readonly date_march: string
  readonly date_apr: string
  readonly date_april: string
  readonly date_may: string
  readonly date_jun: string
  readonly date_june: string
  readonly date_jul: string
  readonly date_july: string
  readonly date_aug: string
  readonly date_august: string
  readonly date_sep: string
  readonly date_september: string
  readonly date_oct: string
  readonly date_october: string
  readonly date_nov: string
  readonly date_november: string
  readonly date_dec: string
  readonly date_december: string

  //resources preliminary
  readonly average: string
  readonly downhill: string
  readonly max: string
  readonly maximum: string
  readonly min: string
  readonly minimum: string
  readonly mode: string
  readonly uphill: string

  //resources
  readonly activity_tracker: string
  readonly altitude: string
  readonly altitude_downhill: string
  readonly altitude_uphill: string
  readonly available: string
  readonly average_downhill: string
  readonly average_percent: string
  readonly average_uphill: string
  readonly average_watt_per_kg: string
  readonly assist: string
  readonly assist_mode: string
  readonly balance: string
  readonly battery: string
  readonly cadence: string
  readonly calories: string
  readonly categories: string
  readonly categories_flat: string
  readonly categories_hilly: string
  readonly categories_mountainous: string
  readonly categories_none: string
  readonly celsius = this.brackets('celsius', Unit.c)
  readonly charts: string
  readonly charts_none: string
  readonly close: string
  readonly differences_downhill: string
  readonly differences_uphill: string
  readonly distance: string
  readonly distance_value: string
  readonly download: string
  readonly download_start: string
  readonly fahrenheit = this.brackets('fahrenheit', Unit.f)
  readonly foot: string
  readonly fullscreen: string
  readonly gps_none: string
  readonly heart_rate: string
  readonly heart_rate_average: string
  readonly hide: string
  readonly kilometer = this.brackets('kilometer', Unit.km)
  readonly kilogram: string
  readonly lap: string
  readonly laps: string
  readonly laps_auto: string
  readonly laps_reg: string
  readonly level_last: string
  readonly map_data: string
  readonly map_notice_desktop: string
  readonly map_notice_mobile: string
  readonly meter = this.brackets('meter', Unit.m)
  readonly miles: string
  readonly mode_0: string
  readonly mode_1: string
  readonly mode_2: string
  readonly mode_3: string
  readonly mode_4: string
  readonly mode_5: string
  readonly mode_6: string
  readonly mode_7: string
  readonly mode_8: string
  readonly mode_9: string
  readonly none: string
  readonly number: string = '#'
  readonly number_value: string = this.number
  readonly pound: string
  readonly power: string
  readonly rating: string
  readonly rating_star: string
  readonly rating_stars: string
  readonly select_language: string
  readonly select_files: string
  readonly settings: string
  readonly show: string
  readonly speed: string
  readonly speed_average: string
  readonly steps: string
  readonly steps_remaining: string
  readonly suitable_for: string
  readonly temperature: string
  readonly time: string
  readonly time_absolute: string
  readonly time_active: string
  readonly time_low: string
  readonly time_high: string
  readonly time_middle: string
  readonly time_value: string
  readonly time_training: string
  readonly times: string
  readonly weight: string

  //others
  readonly cookies_admin_accept: string
  readonly cookies_admin_content: string
  readonly cookies_admin_title: string
  readonly data_privacy: string
  readonly ie_not_supported: string
  readonly imprint: string
  readonly theme: string
  readonly zyxw: string


  //##### complex properties
  readonly errors: {
    configuration_invalid: string,
    configuration_missing: string,
    state_unknown: string,
    identifier_invalid: string,
    identifier_empty: string,
    ip_address_invalid: string,
  };
  protected readonly chart_toolbar: {
    exportToSVG: string,
    exportToPNG: string,
    menu: string,
    selection: string,
    selectionZoom: string,
    zoomIn: string,
    zoomOut: string,
    pan: string,
    reset: string,
  }
  readonly data_privacy_paragraphs: Paragraph[]
  readonly imprint_paragraphs: Paragraph[]


  /**
   * @see https://apexcharts.com/docs/localization/
   */
  get chart(){
    return {
      name: this.slug,
      options: {
        months: [
          this.date_january,
          this.date_february,
          this.date_march,
          this.date_april,
          this.date_may,
          this.date_june,
          this.date_july,
          this.date_august,
          this.date_september,
          this.date_october,
          this.date_november,
          this.date_december,
        ],
        shortMonths: [
          this.date_jan,
          this.date_feb,
          this.date_mar,
          this.date_apr,
          this.date_may,
          this.date_jun,
          this.date_jul,
          this.date_aug,
          this.date_sep,
          this.date_oct,
          this.date_nov,
          this.date_dec,
        ],
        days: [
          this.date_sunday,
          this.date_monday,
          this.date_tuesday,
          this.date_wednesday,
          this.date_thursday,
          this.date_friday,
          this.date_saturday,
        ],
        shortDays: [
          this.date_sun,
          this.date_mon,
          this.date_tue,
          this.date_wed,
          this.date_thu,
          this.date_fri,
          this.date_sat,
        ],
        toolbar: this.chart_toolbar
      }
    }
  }


  protected brackets(prefix: string, suffix: Strimber): string {
    return `${prefix} (${suffix})`
  }
}
