//hammer.js in angular
//@see https://levelup.gitconnected.com/making-hammerjs-work-with-angular-9-81d289159320
//@see https://medium.com/madewithply/ionic-4-long-press-gestures-96cf1e44098b
//@see https://medium.com/angular-in-depth/gestures-in-an-angular-application-dde71804c0d0

import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//PM (18.03.2020)
import {HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {NgApexchartsModule} from 'ng-apexcharts';
//...
import {HomeComponent} from './pages/home/home.component';
import {DataPrivacyComponent} from './pages/data-privacy/data-privacy.component';
import {ImprintComponent} from './pages/imprint/imprint.component';
import {ResourceComponent} from './pages/resource/resource.component';
import {ResourceActivityComponent} from './pages/resource-activity/resource.activity.component';
import {ResourceRouteComponent} from './pages/resource-route/resource.route.component';
import {ResourceTrackerComponent} from './pages/resource-tracker/resource.tracker.component';
import {ThemeComponent} from './pages/theme/theme.component';
//...
import {HeaderComponent} from './sections/header/header.component';
import {SettingsComponent} from './sections/settings/settings.component';
import {DownloadComponent} from './sections/download/download.component';
import {TitleComponent} from './sections/title/title.component';
import {HeadlineComponent} from './sections/headline/headline.component';
import {IntroComponent} from './sections/intro/intro.component';
import {FooterComponent} from './sections/footer/footer.component';
import {ChartBarComponent} from './sections/chart-bar/chart.bar.component';
import {ChartAreaComponent} from './sections/chart-area/chart.area.component';
import {GraphComponent} from './sections/graph/graph.component';
import {MapComponent} from './sections/map/map.component';
import {LapsComponent} from './sections/laps/laps.component';
import {ValuePipe} from './utils/values';
import {UnitPipe} from './utils/units';
import {ParagraphComponent} from './sections/paragraph/paragraph.component';
import {CookiesAdminComponent} from './sections/cookies-admin/cookies-admin.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DataPrivacyComponent,
    ImprintComponent,
    ThemeComponent,
    ResourceComponent,
    ResourceActivityComponent,
    ResourceRouteComponent,
    ResourceTrackerComponent,

    HeaderComponent,
    SettingsComponent,
    DownloadComponent,
    TitleComponent,
    HeadlineComponent,
    IntroComponent,
    FooterComponent,
    ChartBarComponent,
    ChartAreaComponent,
    GraphComponent,
    MapComponent,
    LapsComponent,
    ParagraphComponent,
    CookiesAdminComponent,

    ValuePipe,
    UnitPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgApexchartsModule,
    HammerModule
  ],
  providers: [
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
