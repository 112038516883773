<!--
  this script is used to create the settings HTML snippet
  @since PM (xx.xx.2020)
-->
<section id="{{slug}}" class="modal left" tabindex="-1" role="dialog" [attr.aria-hidden]="true" (click)="close($event)">
  <div class="modal-{{slug}}">
    <div class="text-right modal-close">
      <button type="button" class="button-icon close" [attr.data-dismiss]="'modal'" [attr.aria-label]="theo('close')">
        <i class="icon-close"></i>
      </button>
    </div>
    <div>
      <div class="p-2">
        <h2 class="font-color mb-3 mb-md-1" [innerHTML]="theo('settings')"></h2>
      </div>
      <div *ngFor="let setting of settings; let i = index" class="p-2 row setting setting-{{setting.identifier}}">
        <div class="col-12 name" [innerHTML]="theo(setting.name || setting.identifier)"></div>

        <div *ngIf="setting.list && setting.identifier == 'language'" class="col-12 row values values-list">
          <div *ngFor="let value of setting.list" class="col-4 row">
            <div class="col-4 value value-{{value}}">{{value}}</div>
            <div class="col-8">
              <i *ngIf="setting.value == value; else elseIcon" class="icon-circle clickable"></i>
              <ng-template #elseIcon>
                <i class="icon-circle-o clickable" (click)="update(setting.identifier, value, i)"></i>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="setting.list && setting.identifier != 'language'" class="col-12 row values values-list">
          <div *ngFor="let value of setting.list" class="col-4 row">
            <div class="col-4 value value-{{value}}" [innerHTML]="theo(value)"></div>
            <div class="col-8">
              <i *ngIf="setting.value == value; else elseBlock" class="icon-circle clickable"></i>
              <ng-template #elseBlock>
                <i class="icon-circle-o clickable" (click)="update(setting.identifier, value, i)"></i>
              </ng-template>
            </div>
          </div>
        </div>
        <div *ngIf="setting.pair" class="col-12 row values values-pair">
          <div class="col-5 value value-left value-{{setting.pair.left}}" [innerHTML]="theo(setting.pair.left)"></div>
          <div class="col-2">
            <i
              *ngIf="setting.value; else elseBlock"
              class="icon-toggle-on rotate-180 clickable"
              (click)="update(setting.identifier, setting.pair.right, i)"
            ></i>
            <ng-template #elseBlock>
              <i
                class="icon-toggle-on clickable"
                (click)="update(setting.identifier, setting.pair.left, i)"
              ></i>
            </ng-template>
          </div>
          <div class="col-5 text-right value value-right value-{{setting.pair.right}}" [innerHTML]="theo(setting.pair.right)"></div>
        </div>
      </div>
    </div>
  </div>
</section>
