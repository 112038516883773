import {Component, Input, ViewEncapsulation} from '@angular/core';
import {Column} from '../../utils/elements';
import {LanguageService} from '../../utils/language.service';
import {Intro} from '../../utils/interfaces';

/**
 * this script is used to create the !!! logic
 * @since PM (xx.xx.2020)
 * @see https://codecraft.tv/courses/angular/quickstart/nesting-components-and-inputs/
 * https://apexcharts.com/docs/options/title/
 */
@Component({
  selector: 'column-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.less'],
  encapsulation: ViewEncapsulation.None //prevent encapsulation of css definitions @ee https://angular.io/api/core/ViewEncapsulation
})
export class IntroComponent extends Column {
  @Input() intro: Intro[]
  readonly slug = 'intro'
  readonly limit = 3
  readonly navigation = {left: false, right: true}
  /*
  private slides: HTMLElement|null
  private navL: HTMLElement|null
  private navR: HTMLElement|null
  */

  //TODO remove
  dummy = ['bmx', 'cyclecross', 'cycling', 'ebike', 'enduro', 'fitness', 'golf', 'hiking']


  ngAfterViewInit() {
    super.ngAfterViewInit();

    /**
     * this can't be used because due to the conditions in ...html
     * even at the time of the invocation of this method the elements will not be available yet
     *//*
    this.slides = this.eltId(this.slug +'_info_slides') //slides container
    this.navL = this.eltId(this.slug +'_navigator_left') //slides container
    this.navR = this.eltId(this.slug +'_navigator_right') //slides container
    */
  }

  previous(event: Event){
    const container = this.eltId(this.slug +'_info_slides')
    if(container){
      const slides = container.querySelectorAll('.info-slide.'+ this.active) //the active sports
      if(slides.length){
        const sibling = slides[0].previousElementSibling
        if(sibling){
          //move to the sibling
          slides[slides.length - 1].classList.remove(this.active)
          sibling.classList.add(this.active)

          //the buttons state
          if(!sibling.previousElementSibling) this.navigation.left = false //this.eltEv(event).classList.add(this.disabled)
          this.navigation.right = true //this.eltId(this.slug +'_navigator_right')?.classList.remove(this.disabled)
        }
      }
    }
  }
  next(event: Event){
    const container = this.eltId(this.slug +'_info_slides')
    if(container){
      const slides = container.querySelectorAll('.info-slide.'+ this.active) //the active sports
      if(slides.length){
        const sibling = slides[slides.length - 1].nextElementSibling
        if(sibling){
          //move to the sibling
          slides[0].classList.remove(this.active)
          sibling.classList.add(this.active)

          //the buttons state
          if(!sibling.nextElementSibling) this.navigation.right = false //this.eltEv(event).classList.add(this.disabled)
          this.navigation.left = true //this.eltId(this.slug +'_navigator_left')?.classList.remove(this.disabled)
        }
      }
    }
  }
}
