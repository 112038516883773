<aside class="modals">
  <modal-settings [lang]="lang" [full]="false"></modal-settings>
</aside>
<main>
  <article class="article article-{{slug}}">
    <section-header></section-header>
    <section class="container">
      <row-headline [target]="sectionIdentifier.overview" [head]="head" [uppercase]="true" [icon]="false"></row-headline>

      <row-paragraph
        *ngFor="let paragraph of language.theo.data_privacy_paragraphs"
        [paragraph]="paragraph"
      ></row-paragraph>
    </section>
    <section-footer></section-footer>
  </article>
</main>
