<!--
  this script is used to create the introduction HTML snippet
  @since PM (18.03.2020)
  @see https://placeholder.pics/
       https://placeholder.pics/svg/100x100/DEDEDE/555555/%3F%3F%3F
-->
<div
  *ngFor="let item of intro"
  class="row line-color mb-2 mt-1 mt-md-2 pb-2 pt-2 {{slug}} {{slug}}-{{item.flag}}"
>
  <div class="info-icon col-2 col-md-2 col-xl-1 text-left">
    <img  src="{{imagesIntro + item.flag}}.svg" class="mt-1 mt-md-1 mt-lg-0">
  </div>
  <div class="info-label col-4 col-md-4 col-xl-4 mx-0 px-0 pl-2 pl-sm-0 pl-md-2 pl-lg-0 pl-xl-0 ml-lg-0 ">
    <span class="fs-21 center-y text-left">{{ theo(item.label) }}</span>
  </div>

  <div
    [ngSwitch]="item.type"
    class="col-6 col-md-6 info-col-{{item.type}}"
  >
    <div
      *ngSwitchCase="'categories'"
      class="ml-lg-3 mr-lg-3 text-right info-values info-values-{{item.type}}"
    >
      <!--
      <img
        *ngFor="let value of item.value"
        class="info-value-image"
        src="{{imagesIntro + item.type +'-'+ value +'.svg'}}"
        alt="{{lipsum(item.type +'-'+ value +'.svg')}}"
        title="{{theo(item.type +'_'+ value)}}"
      >
      -->
      <span
        *ngFor="let value of item.value"
        class="badge badge-secondary info-value-badge info-value-{{value}}"
        [innerText]="theo(item.type +'_'+ value)"
      ></span>
    </div>
    <div
      *ngSwitchCase="'rating'"
      class="ml-lg-3 mr-lg-3 text-right info-values info-values-{{item.type}}"
    >
      <i
        *ngFor="let nbr of [1, 2, 3, 4, 5]"
        class="info-value-icon icon-star{{nbr > item.value ? '-o' : ''}}"
        title="{{ item.value +' '+ (item.value == 1 ? theo('rating_star') : theo('rating_stars')) }}"
      ></i>
    </div>
    <div
      *ngSwitchCase="'sports'"
      class="ml-lg-3 mr-lg-3 text-right row info-values info-values-{{item.type}}"
    >
      <div
        *ngIf="item.value.length > limit"
        class="col-1 text-left info-navigator info-navigator-left"
      >
        <i
          id="{{slug}}_navigator_left"
          class="icon-chevron-left center clickable {{navigation.left ? '' : disabled}}"
          (click)="previous($event)"
        ></i>
      </div>
      <div
        class="col {{item.value.length > limit ? 'info-slides' : 'p-0 info-slides-reverse'}}"
        id="{{slug}}_info_slides"
      >
        <img
          *ngFor="let value of item.value; let i = index"
          class="info-value-image info-slide {{i < limit ? active : ''}}"
          src="{{imagesDiscColor + kebabise(value) +'.svg'}}"
          alt="{{ lipsum(value +'.svg') }}"
          title="{{ theo(value) }}"
        >
      </div>
      <div
        *ngIf="item.value.length > limit"
        class="col-1 text-right info-navigator info-navigator-right"
      >
        <i
          id="{{slug}}_navigator_right"
          class="icon-chevron-right center clickable {{navigation.right ? '' : disabled}}"
          (click)="next($event)"
        ></i>
      </div>
    </div>
    <div *ngSwitchDefault class="text-right">
      <span class="fs-24 text-right font-weight-bold mr-2 info-value">{{item.type | parseValue: item.value}}</span>
      <span class="fs-17 text-left info-unit">{{item.type | getUnit}}</span>
    </div>
  </div>
</div>
